import { HttpClient } from '@angular/common/http';
import { Injectable, SecurityContext, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { GroupNames, PrintType } from '../models/enumlist';
import { PreviewWordTemplates, TemplateGroupParams } from '../models/groupparams';
import { TemplateStatementParams } from '../models/statement';
import { DomSanitizer } from '@angular/platform-browser';
import { StringLiteralLike } from 'typescript';
import { HttpParams } from '@angular/common/http';
import { TemplateReceiptParams } from '../models/receipt';
import { TemplateVoucherParams } from '../models/voucher';
import { TemplateInvoiceReceiptParams } from '../models/invoicereceipt';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  baseUrl = environment.templateApiUrl;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  printPOPDFForm(transId: any, printComp: any,templateType: string = "", isNewViewScreen: boolean = false) {
    let params = new TemplateGroupParams();
    params.printType = PrintType.PDF;
    params.isMockData = false;
    params.groupId = GroupNames.PurchaseOrder;
    params.transIds = String(transId);
    params.templateId = 1;
    params.orgId = 1;
    params.userId = 1;
    params.templateType = templateType;
    params.isNewViewScreen = isNewViewScreen;

    this.getPDFForm(params).subscribe(response => {
      printComp.pdfSrc = response; // pdfSrc can be Blob or Uint8Array
      printComp.refresh(); // Ask pdf viewer to load/reresh pdf
    }
    )
  }

  printBillPDFForm(transId: any, printComp: any,templateType: string = "", isNewViewScreen: boolean = false) {
    let params = new TemplateGroupParams();
    params.printType = PrintType.PDF;
    params.isMockData = false;
    params.groupId = GroupNames.Bills;
    params.transIds = String(transId);
    params.templateId = 1;
    params.orgId = 1;
    params.userId = 1;
    params.templateType=templateType;
    params.isNewViewScreen = isNewViewScreen;

    this.getPDFForm(params).subscribe(response => {
      printComp.pdfSrc = response; // pdfSrc can be Blob or Uint8Array
      printComp.refresh(); // Ask pdf viewer to load/reresh pdf
    }
    )
  }

  printQuotePDFForm(transId: any, printComp: any) {
    let params = new TemplateGroupParams();
    params.printType = PrintType.PDF;
    params.isMockData = false;
    params.groupId = GroupNames.Quotes;
    params.transIds = String(transId);
    params.templateId = 1;
    params.orgId = 1;
    params.userId = 1;

    this.getPDFForm(params).subscribe(response => {
      printComp.pdfSrc = response; // pdfSrc can be Blob or Uint8Array
      printComp.refresh(); // Ask pdf viewer to load/reresh pdf
    }
    )
  }

  printReceiptPDFForm(transId: any, templateId: any, printComp: any,templateType: string, isBatch: boolean = false, IsNewViewScreen: boolean = false) {
    let params = new TemplateReceiptParams();
    params.printType = PrintType.PDF;
    params.isMockData = false;
    params.groupId = GroupNames.Receipt;
    params.transIds = String(transId);
    params.templateId = templateId;
    params.orgId = 1;
    params.userId = 1;
    params.isBatch = isBatch;
    params.templateType = templateType;
    params.IsNewViewScreen = IsNewViewScreen;

    this.getReceiptPDFForm(params).subscribe(response => {
      printComp.pdfSrc = response; // pdfSrc can be Blob or Uint8Array
      printComp.refresh(); // Ask pdf viewer to load/reresh pdf
    }
    )
  }

  printInvoiceReceiptPDFForm(transId: any, templateId: any, printComp: any,templateType: string, isBatch: boolean = false, IsNewViewScreen: boolean = false) {
    let params = new TemplateReceiptParams();
    params.printType = PrintType.PDF;
    params.isMockData = false;
    params.groupId = GroupNames.InvoiceReceipt;
    params.transIds = String(transId);
    params.templateId = templateId;
    params.orgId = 1;
    params.userId = 1;
    params.isBatch = isBatch;
    params.templateType= templateType;
    params.IsNewViewScreen = IsNewViewScreen;

    this.getInvoiceReceiptPDFForm(params).subscribe(response => {
      printComp.pdfSrc = response; // pdfSrc can be Blob or Uint8Array
      printComp.refresh(); // Ask pdf viewer to load/reresh pdf
    }
    )
  }

  printVoucherPDFForm(transId: any, templateId: any, printComp: any,templateType: string, isBatch: boolean = false, isNewViewScreen: boolean = false) {
    let params = new TemplateVoucherParams();
    params.printType = PrintType.PDF;
    params.isMockData = false;
    params.groupId = GroupNames.Voucher;
    params.transIds = String(transId);
    params.templateId = templateId;
    params.orgId = 1;
    params.userId = 1;
    params.isBatch = isBatch;
    params.templateType = templateType;
    params.isNewViewScreen = isNewViewScreen;

    this.getVoucherPDFForm(params).subscribe(response => {
      printComp.pdfSrc = response; // pdfSrc can be Blob or Uint8Array
      printComp.refresh(); // Ask pdf viewer to load/reresh pdf
    }
    )
  }

  printBillVoucherPDFForm(transId: any, templateId: any, printComp: any,templateType: string, isBatch: boolean = false, isNewViewScreen: boolean = false) {
    let params = new TemplateVoucherParams();
    params.printType = PrintType.PDF;
    params.isMockData = false;
    params.groupId = GroupNames.BillVoucher;
    params.transIds = String(transId);
    params.templateId = templateId;
    params.orgId = 1;
    params.userId = 1;
    params.isBatch = isBatch;
    params.templateType = templateType;
    params.isNewViewScreen = isNewViewScreen;

    this.getBillVoucherPDFForm(params).subscribe(response => {
      printComp.pdfSrc = response; // pdfSrc can be Blob or Uint8Array
      printComp.refresh(); // Ask pdf viewer to load/reresh pdf
    }
    )
  }

  printStatementPDFForm(transIds: any, statementType: any, fromDate: any, toDate: any, templateId: any,templateType:string, printComp: any,interval?:any,duration?:any,ageType?:any) {
    let params = new TemplateStatementParams();
    params.printType = PrintType.PDF;
    params.isMockData = false;
    params.groupId = GroupNames.Statement; 
    params.transIds = transIds;
    params.templateId = templateId;
    params.orgId = 1;
    params.userId = 1;
    params.statementType = statementType;
    params.fromDate = fromDate;
    params.toDate = toDate;
    params.templateType=templateType;
    params.interval=interval;
    params.duration=duration;
    params.ageType=ageType;

    this.getStatementPDFForm(params).subscribe(response => {
      printComp.pdfSrc = response; // pdfSrc can be Blob or Uint8Array
      printComp.refresh(); // Ask pdf viewer to load/reresh pdf
    }
    )
  }

  /* Download statement PDF file*/
  downloadStatementPDF(transIds: any, statementType: any, fromDate: any, toDate: any, templateId: any, fileName: any,templateType:string,interval?:any,duration?:any,ageType?:any) {
    let params = new TemplateStatementParams();
    params.printType = PrintType.PDF;
    params.isMockData = false;
    params.groupId = GroupNames.Statement;
    params.transIds = transIds;
    params.templateId = templateId;
    params.orgId = 1;
    params.userId = 1;
    params.statementType = statementType;
    params.fromDate = fromDate;
    params.toDate = toDate;
    params.templateType = templateType;
    params.interval=interval;
    params.duration=duration;
    params.ageType=ageType;

    this.getStatementPDFForm(params).subscribe(response => {
      this.getFile(response, fileName);
    }
    )
  }

  /* Convert blob type to a file and download automatically without any event */
  getFile(file: any, fname: string) {
    var fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
    var a = document.createElement('a');
    a.href = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, fileURL);
    a.download = fname;
    document.body.appendChild(a);
    a.click();
  }


  printPDFForm(group: GroupNames, transId: any, printComp: any, templateType: string, isNewViewScreen = false) {
    let params = new TemplateGroupParams();
    params.printType = PrintType.PDF;
    params.isMockData = false;
    params.groupId = group;
    params.transIds = String(transId);
    params.templateId = 1;
    params.orgId = 1;
    params.userId = 1;
    params.templateType = templateType;
    params.isNewViewScreen = isNewViewScreen;

    this.getPDFForm(params).subscribe(response => {
      printComp.pdfSrc = response; // pdfSrc can be Blob or Uint8Array
      printComp.refresh(); // Ask pdf viewer to load/reresh pdf
    }
    )
  }

  printSTPDFForm(group: GroupNames, transId: any, printComp: any, templateType: string, templateId: any,isNewViewScreen = false) {
    let params = new TemplateGroupParams();
    params.printType = PrintType.PDF;
    params.isMockData = false;
    params.groupId = group;
    params.transIds = String(transId);
    params.templateId = templateId;
    params.orgId = 1;
    params.userId = 1;
    params.templateType = templateType;
    params.isNewViewScreen = isNewViewScreen;

    this.getPDFForm(params).subscribe(response => {
      printComp.pdfSrc = response; // pdfSrc can be Blob or Uint8Array
      printComp.refresh(); // Ask pdf viewer to load/reresh pdf
    }
    )
  }

  printPdf(pdfViewerOnDemand: any) {
    let res = pdfViewerOnDemand.pdfSrc;
    const pdf = new Blob([res], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(pdf);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl));
    document.body.appendChild(iframe);
    //iframe1.contentWindow.print();
    setTimeout(() => {
      iframe.contentWindow.print();
  }, 100);
  }

  printOnlineQuotePDFForm(group: GroupNames, transId: any, orgid: any, CustCode: any, printComp: any, templateType:string) {
    let params = new TemplateGroupParams();
    params.printType = PrintType.PDF;
    params.isMockData = false;
    params.groupId = group;
    params.transIds = String(transId);
    params.templateId = 1;
    params.orgId = orgid;
    params.userId = 0;
    params.templateType=templateType;

    this.getOnlineQuotePDFForm(params, CustCode).subscribe(response => {
      printComp.pdfSrc = response; // pdfSrc can be Blob or Uint8Array
      printComp.refresh(); // Ask pdf viewer to load/reresh pdf
    }
    )
  }

  printOnlineInvoicePDFForm(group: GroupNames, transId: any, orgid: any, CustCode: any, printComp: any, templateType:string) {
    let params = new TemplateGroupParams();
    params.printType = PrintType.PDF;
    params.isMockData = false;
    params.groupId = group;
    params.transIds = String(transId);
    params.templateId = 1;
    params.orgId = orgid;
    params.userId = 0;
    params.templateType=templateType;

    this.getOnlineInvoicePDFForm(params, CustCode).subscribe(response => {
      printComp.pdfSrc = response; // pdfSrc can be Blob or Uint8Array
      printComp.refresh(); // Ask pdf viewer to load/reresh pdf
    }
    )
  }

  printMultiplePDFForm(tempgroup: TemplateGroupParams[], printComp: any) {
    this.getMultiGroupPDFForm(tempgroup).subscribe(response => {
      printComp.pdfSrc = response; // pdfSrc can be Blob or Uint8Array
      printComp.refresh(); // Ask pdf viewer to load/reresh pdf
    }
    )
  }

  getPDFForm(params: TemplateGroupParams) {
    let apiurl = "";
    apiurl = this.baseUrl + '/Templates/print';
    return this.http.post(apiurl, params, { responseType: 'blob' }).pipe(
      map(response => {
        return response;
      })
    );
  }

  getOnlineInvoicePDFForm(Templateparams: TemplateGroupParams, CustCode?: number) {
    let params = new HttpParams();
    params = params.set('tmpParams', JSON.stringify(Templateparams));
    params = params.set('CustCode', CustCode.toString());
    return this.http.post(this.baseUrl + '/Templates/printonlineinvoice', params, { responseType: 'blob' }).pipe(
      map(response => {
        return response;
      })
    )
  }

  getOnlineQuotePDFForm(Templateparams: TemplateGroupParams, CustCode?: number) {
    let params = new HttpParams();
    params = params.set('tmpParams', JSON.stringify(Templateparams));
    params = params.set('CustCode', CustCode.toString());
    return this.http.post(this.baseUrl + '/TemplatesAnonymous/print', params, { responseType: 'blob' }).pipe(
      map(response => {
        return response;
      })
    )
  }

  getMultiGroupPDFForm(params: TemplateGroupParams[]) {
    let apiurl = "";
    apiurl = this.baseUrl + '/Templates/printmultiple';
    return this.http.post(apiurl, params, { responseType: 'blob' }).pipe(
      map(response => {
        return response;
      })
    )
  }

  getStatementPDFForm(params: TemplateStatementParams) {
    return this.http.post(this.baseUrl + '/Templates/printstatement', params, { responseType: 'blob' }).pipe(
      map(response => {
        return response;
      })
    )
  }

  getReceiptPDFForm(params: TemplateReceiptParams) {
    return this.http.post(this.baseUrl + '/Templates/printreceipt', params, { responseType: 'blob' }).pipe(
      map(response => {
        return response;
      })
    )
  }

  getInvoiceReceiptPDFForm(params: TemplateInvoiceReceiptParams) {
    return this.http.post(this.baseUrl + '/Templates/printinvoiecreceipt', params, { responseType: 'blob' }).pipe(
      map(response => {
        return response;
      })
    )
  }

  getVoucherPDFForm(params: TemplateVoucherParams) {
    return this.http.post(this.baseUrl + '/Templates/printvoucher', params, { responseType: 'blob' }).pipe(
      map(response => {
        return response;
      })
    )
  }

  getBillVoucherPDFForm(params: TemplateVoucherParams) {
    return this.http.post(this.baseUrl + '/Templates/printbillvoucher', params, { responseType: 'blob' }).pipe(
      map(response => {
        return response;
      })
    )
  }

  returnEncodedString(value: any) {
    if (value == null) return value;
    value = value.replace(/%27/g, "'");
    var encValue = encodeURIComponent(value.replace(/"/g, "%22").replace(/'/g, "%27")).replaceAll('%09', '\t');
    try {
      if (decodeURIComponent(decodeURIComponent(encValue)) === value) {
        //not encodec yet...so return encoded of val
        return encValue;
      }
    } catch (err) {
      //not encodec yet...so return encoded of val
      return encValue;
    }

    return value;  //same value returned
  }

  getPreviewWordTemplate(previewParam: PreviewWordTemplates) {
    return this.http.post(this.baseUrl + '/Templates/previewWordTemplate', previewParam, { responseType: 'blob' })
      .pipe(
        map(response => {
          return response;
        })
      )
  }

  PreviewPlaceHolders(previewParam: PreviewWordTemplates) {
    return this.http.post(this.baseUrl + '/Templates/PreviewPlaceHolders', previewParam, { responseType: 'text' as 'json' })
      .pipe(
        map(response => {
          return response;
        })
      )
  }
  printPayableStatementPDFForm(transIds: any, statementType: any, fromDate: any, toDate: any, templateId: any,templateType:string, printComp: any,interval?:any,duration?:any,ageType?:any) {
    let params = new TemplateStatementParams();
    params.printType = PrintType.PDF;
    params.isMockData = false;
    params.groupId = GroupNames.PayableStatment; 
    params.transIds = transIds;
    params.templateId = templateId;
    params.orgId = 1;
    params.userId = 1;
    params.statementType = statementType;
    params.fromDate = fromDate;
    params.toDate = toDate;
    params.templateType=templateType;
    params.interval=interval;
    params.duration=duration;
    params.ageType=ageType;

    this.getStatementPDFForm(params).subscribe(response => {
      printComp.pdfSrc = response; // pdfSrc can be Blob or Uint8Array
      printComp.refresh(); // Ask pdf viewer to load/reresh pdf
    }
    )
  }
  downloadPayableStatementPDF(transIds: any, statementType: any, fromDate: any, toDate: any, templateId: any, fileName: any,templateType:string,interval?:any,duration?:any,ageType?:any) {
    let params = new TemplateStatementParams();
    params.printType = PrintType.PDF;
    params.isMockData = false;
    params.groupId = GroupNames.PayableStatment;
    params.transIds = transIds;
    params.templateId = templateId;
    params.orgId = 1;
    params.userId = 1;
    params.statementType = statementType;
    params.fromDate = fromDate;
    params.toDate = toDate;
    params.templateType = templateType;
    params.interval=interval;
    params.duration=duration;
    params.ageType=ageType;

    this.getStatementPDFForm(params).subscribe(response => {
      this.getFile(response, fileName);
    }
    )
  }
}
