<div id="RecurringInvoicemodal" bsModal #showRecurringInvoice="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="showRecurringInvoiceLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" *ngIf="transTypeID=='6'">{{transID=='0' || recurrenceSettingobj==null ? 'New
                    Recurring Invoice Setting' : 'Edit Recurring Invoice Setting'}}</h4>
                <h4 class="modal-title" *ngIf="transTypeID=='2'">{{transID=='0' || recurrenceSettingobj==null ? 'New
                    Recurring Bill Setting' : 'Edit Recurring Bill Setting'}}</h4>
                <button type="button" class="close" (click)="onRecurringSettingCancel()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="needs-validation" novalidate="novalidate" [formGroup]="step1Form">
                    <div class="row">
                        <div class="col-md-8 pl-3  d-flex">
                            <div class="custom-control-mid custom-checkbox">
                                <input type="checkbox" class="custom-control-input" formControlName="enableRecurring"
                                    (change)="changeEnableRecurring($event)" id="chkenableRecurring">
                                <label class="custom-control-label" for="chkenableRecurring">{{'PurchaseModule.Bills.Enable Recurring Setting' | translate}}</label>
                            </div>
                        </div>
                    </div>
                    <label class="col-form-label" for="inputto">{{'PurchaseModule.Bills.Recurring Interval' | translate}}<span
                            class="text-danger">*</span></label>
                    <div class="pb-2 row">

                        <div class="col-md-3">

                            <input [readonly]="isEnableDiv" class="form-control mr-2" type="text"
                                formControlName="interval" autocomplete="off" maxlength="3" id="Interval" numbers
                                [ngClass]="{ 'is-invalid': !isEnableDiv && isSubmitted && f.interval.errors }" (change)="getRecurringOccurrences()" />
                            <div *ngIf=" !isEnableDiv && isSubmitted && f.interval.errors" class="invalid-feedback">
                                <div *ngIf="f.interval.errors.required">
                                    {{'Common.Is required' | translate}}
                                </div>
                                <div *ngIf="f.interval.errors.pattern">
                                    {{'PurchaseModule.Bills.Please provide numbers only.' | translate}}
                                </div>
                                <div *ngIf="f.interval.errors.min">
                                    {{'PurchaseModule.Bills.should be greater than 0.' | translate}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5 pl-0">

                            <ng-select [readonly]="isEnableDiv" [items]="lstFilters" class=""
                                formControlName="intervalValue" [clearable]="false" [clearOnBackspace]="false"
                                bindValue="value" bindLabel="filterselect" [multiple]="false"
                                [ngClass]="{ 'is-invalid':f.intervalValue.errors }"
                                (change)="getRecurringOccurrences()">
                            </ng-select>


                            <div *ngIf="f.intervalValue.errors" class="invalid-feedback">{{'PurchaseModule.Bills.Please provide select interval' | translate}}
                            </div>
                        </div>
                        <label class="col-form-label float-right" for="once">{{'PurchaseModule.Bills.Once' | translate}}</label>
                    </div>

                    <div class="pb-2 row">
                        <div class="col-md-4">
                            <label for="name">{{'Common.Start Date' | translate}}<span class="text-danger">*</span></label>
                            <input [disabled]="isEnableDiv" class="form-control" formControlName="fromDate"
                                placeholder="dd/mm/yyyy" name="d4" date ngbDatepicker maxlength="10" autocomplete="off"
                                [minDate]="minFDate" #d4="ngbDatepicker" (click)="d4.toggle()"
                                (dateSelect)="changeStartOn();"
                                [ngClass]="{'is-invalid': !isEnableDiv && isSubmitted && f.fromDate.errors }">
                            <div *ngIf="!isEnableDiv && isSubmitted && f.fromDate.errors" class="invalid-feedback">
                                {{'Common.Please provide a valid date' | translate}}
                            </div>
                            <div *ngIf="startDateErrorMsg?.length != 0"
                                style="color: red;font-size:10px;" class="error_message ng-invalid">
                                <span class="e_arrow"></span>
                                <i>{{ startDateErrorMsg }}</i>
                            </div>
                        </div>
                        <div class="col-md-4 pl-0">
                            <label for="name">{{'Common.End Date' | translate}}</label>
                            <input [disabled]="isEnableDiv || isneverEnd" class="form-control" formControlName="toDate"
                                placeholder="dd/mm/yyyy" name="d2" date ngbDatepicker maxlength="10" autocomplete="off"
                                [minDate]="minDate" #d2="ngbDatepicker" (click)="d2.toggle()"
                                (dateSelect)="changeEndOn();"
                                 [ngClass]="{'is-invalid':isSubmitted && f.toDate.errors }">
                                 <div *ngIf="isSubmitted && f.toDate.errors" class="invalid-feedback">{{'Common.Please provide a valid date' | translate}}</div>

                            <!-- <div *ngIf="isSubmitted && toDateErrorMsg?.length != 0" style="color: red;font-size:10px;" class="error_message ng-invalid"> -->
                            <div *ngIf="(step1Form.value.toDate === null && step1Form.value.neverEnd !== true) || (step1Form.value.toDate !== null && step1Form.value.neverEnd !== true && toDateErrorMsg?.length != 0)"
                                style="color: red;font-size:10px;" class="error_message ng-invalid">
                                <span class="e_arrow"></span>
                                <i>{{ toDateErrorMsg }}</i>
                            </div>

                        </div>
                        <div class="col-md-4 pl-0 d-flex">
                            <div class="custom-control-bottom custom-checkbox d-flex">
                                <input *ngIf="isEnableDiv" disabled type="checkbox" class="custom-control-input"
                                    formControlName="neverEnd" (change)="changeNeverEnd($event)" id="chkNeverEnd">
                                <input *ngIf="!isEnableDiv" type="checkbox" class="custom-control-input"
                                    formControlName="neverEnd" (change)="changeNeverEnd($event)" id="chkNeverEnd">
                                <label class="custom-control-label" for="chkNeverEnd">{{'PurchaseModule.Bills.Never Expires' | translate}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="pb-2 row">
                        <div class="col-md-8">
                            <label class="col-form-label" for="name">{{'Common.Status' | translate}}<span class="text-danger">*</span></label>
                            <ng-select [readonly]="isEnableDiv" [items]="lstStatus" formControlName="statusID"
                                [clearable]="false" [clearOnBackspace]="false" [multiple]="false" bindValue="value"
                                bindLabel="filterselect">
                            </ng-select>

                            <div *ngIf="isSubmitted && statusErrorMsg?.length != 0" style="color: red;font-size:10px;"
                                class="error_message ng-invalid">
                                <span class="e_arrow"></span>
                                <i>{{ statusErrorMsg }}</i>
                            </div>
                        </div>

                    </div>

                    <div class="pb-2 row">
                        <div class="col-md-12" *ngIf="!isneverEnd">
                            <label class="col-form-label text-info pr-4" for="name"> {{'PurchaseModule.Bills.Total Occurences' | translate}}: {{
                                totalOccurrences }}</label>
                            <label class="col-form-label text-danger" for="name"> {{'PurchaseModule.Bills.Pending Occurences' | translate}}: {{
                                pendingOccurrences }}</label>

                        </div>
                        <div class="col-md-12" *ngIf="!isEnableDiv">
                            <label class="col-form-label text-warning" *ngIf="transTypeID=='6'" for="name"> {{'PurchaseModule.Bills.Next Invoice Date' | translate}}: {{ nextInvoiceDate | date:'dd/MM/YYYY' }}</label>
                            <label class="col-form-label text-warning" *ngIf="transTypeID=='2'" for="name"> {{'PurchaseModule.Bills.Next Bill Date' | translate}}: {{ nextInvoiceDate | date:'dd/MM/YYYY' }}</label>
                        </div>

                        <div class="col-md-12 mt-4">
                            <div class="form-title mb-1"> {{'PurchaseModule.Bills.Note' | translate}} &nbsp; <span
                                    class="fa fa-exclamation-triangle text-warning"></span></div>
                            <div class="col-12" *ngIf="transTypeID=='6'">
                                <div class="form-group row">
                                    <ul>
                                        <li><small class="text-danger"> {{'PurchaseModule.Bills.Parent invoice will not be counted in the total occurences' | translate}}. </small></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-12" *ngIf="transTypeID=='2'">
                                <div class="form-group row">
                                    <ul>
                                        <li><small class="text-danger">{{'PurchaseModule.Bills.Parent bill will not be counted in the total occurences.' | translate}} </small></li>
                                    </ul>
                                </div>
                            </div>                           
                        </div>
                    </div>

                </form>
            </div>
            <div class="modal-footer">
                <button *ngIf="!isOptional" class="btn btn-primary mr-2" (click)="SaveUpdateSetting()">
                    {{'Common.Submit' | translate}}</button>
                <button (click)="onRecurringSettingCancel()" class="btn btn-danger pull-right">{{'Common.Cancel' | translate}}</button>
            </div>
        </div>
    </div>
</div>