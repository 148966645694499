<ng-select #selectContact [items]="lstContacts" [virtualScroll]="true" [loading]="loadingContact"
    *ngIf="!isPartiallypaid && !inactiveContact" bindLabel="contactName" bindValue="contactID"
    placeholder="{{'SharedModule.Select Contact' | translate}}" (scroll)="onScroll($event)" (scrollToEnd)="onScrollToEnd()" [(ngModel)]="contactID"
    (search)="onSearchForApi($event)" (change)="onSelectContact($event)" [searchFn]="customSearchFn"
    [ngClass]="{ 'is-invalid': contactInvalid && (contactID == 0 || contactID == null)}" (clear)="onClear()"
    (open)="onOpen()" [inputAttrs]="{ maxlength: 200 }">
    <ng-container *userCanEdit="[true]">
        <ng-template ng-header-tmp *ngIf="isSaveContact && uarContact">
            <a class="t-link" (click)="saveNewContact()"><b>+</b>{{'ContactModule.Contact.Save New Contact' | translate}}
            </a>
        </ng-template>
    </ng-container>

    <ng-template ng-option-tmp let-item="item" let-index="index">
        <span *ngIf="item.isLHDNverified"   triggers="mouseenter:mouseleave" [popover]="'LHDN Verified'"  class="fa fa-check-circle" style="color:#41c241"></span>
        {{item.contactName }}
    </ng-template> 
</ng-select>
<input *ngIf="!isPartiallypaid && inactiveContact" class="form-control" [(ngModel)]="contactName"
    (keyup)='inactiveContact=false;'>
<input *ngIf="isPartiallypaid" class="form-control" disabled [(ngModel)]="contactName">

<div *ngIf="contactInvalid && (contactID == 0 || contactID == null)" class="invalid-feedback">
    {{'ContactModule.Contact.Please select contact' | translate}}
</div>
<div *ngIf="!contactInvalid && archiveInvalid && contactID != 0" style="color: red;" class="error_message ng-invalid">
    {{'ContactModule.Contact.This contact is archived. Please select other contact or restore.' | translate}}
</div>
