import { DatePipe } from "@angular/common";
import * as moment from "moment";
export const toolTipValueGetter = (params) => ({ value: params.value });
export function anchorCellRender(params: any) {
  let eGui = document.createElement("div");
  eGui.innerHTML = `<a class="t-link edit">${params.value}</a>`;
  return eGui;
}

export function currencyFormatter(params) {
  let value;
  if (params.value == undefined || params.value == null) {
    value = params;
  }
  else {
    value = params.value;
  }

  let num;
  if (Number(value) > 0)
    num = Number(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  else if (Number(value) == 0) {
    num = Number(value).toFixed(2);
  }
  else {
    num = "(" + Number(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "") + ")";
  }
  return num;
}
export function dateComparator(date1, date2) {
  var date1Number = date1 && new Date(date1).getTime();
  var date2Number = date2 && new Date(date2).getTime();

  if (date1Number == null && date2Number == null) {
    return 0;
  }

  if (date1Number == null) {
    return -1;
  } else if (date2Number == null) {
    return 1;
  }

  return date1Number - date2Number;
}

export function braceFormatter(params) {
  let num;
  if (params.value > 0)
    num = Number(params.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  else if (Number(params.value) == 0) {
    num = "";
  }
  else {
    num = "(" + Number(params.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "") + ")";
  }
  return num;
}

export function braceFormatternew(params) {
  let num;
  let dgt = params.value.toString().length - Number(params.value).toFixed(0).length-1;
  dgt = dgt < 0 ? 2 : dgt;
  if (params.value > 0)
    //num = Number(params.value).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+Number(params.value - Math.floor(params.value)).toFixed(dgt).replace("0.",".");
    num = Math.floor(Number(params.value)).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+Number(params.value - Math.floor(params.value)).toFixed(dgt).replace("-", "").replace("0.",".");
  else if (Number(params.value) == 0) {
    num = "";
  }
  else {
    num = "(" + Number(params.value).toFixed(dgt).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "") + ")";
    //num = "(" + Number(params.value).toFixed(dgt).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "")+Number(params.value - Math.ceil(params.value)).toFixed(dgt).replace("0.",".") + ")";
    num = "(" + Math.ceil(Number(params.value)).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "")+Number(params.value - Math.ceil(params.value)).toFixed(dgt).replace("-", "").replace("0.",".") + ")";
  }
  return num;
}

export function braceFormatter4(params) {
  let num;
  let dgt = params.value.toString().length - Number(params.value).toFixed(0).length-1;
  dgt = 4;//dgt < 0 ? 4 : dgt;
  if (params.value > 0)
    //num = Number(params.value).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+Number(params.value - Math.floor(params.value)).toFixed(dgt).replace("0.",".");
    num = Math.floor(Number(params.value)).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+Number(params.value - Math.floor(params.value)).toFixed(dgt).replace("-", "").replace("0.",".");
  else if (Number(params.value) == 0) {
    num = "";
  }
  else {
    num = "(" + Number(params.value).toFixed(dgt).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "") + ")";
    //num = "(" + Number(params.value).toFixed(dgt).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "")+Number(params.value - Math.ceil(params.value)).toFixed(dgt).replace("0.",".") + ")";
    num = "(" + Math.ceil(Number(params.value)).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "")+Number(params.value - Math.ceil(params.value)).toFixed(dgt).replace("-", "").replace("0.",".") + ")";
  }
  return num;
}

export function zerobraceFormatter(params) {
  let num;
  if (params.value > 0)
    num = Number(params.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  else if (Number(params.value) == 0) {
    num = Number(params.value).toFixed(2);
  }
  else {
    num = "(" + Number(params.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "") + ")";
  }
  return num;
}

export function actionCellRenderer(params) {
  let eGui = document.createElement("div");
  eGui.innerHTML = `<span class="close-x" data-action="delete">x</span>`;
  return eGui;
}


export function getDefaultSetting(orgdue, orgduetypeid,datevalue?) {
  var retdate = '';
  var date = new Date(datevalue);
  if (orgduetypeid == 1) {
    date.setDate(date.getDate() + orgdue);
    retdate = moment(date).format('YYYY/MM/DD');
    return retdate;
  }
  else if (orgduetypeid == 2) {
    var getdays = daysInMonth(date.getMonth() + 1, date.getFullYear());
    var calcdays = getdays - date.getDate();
    date.setDate(date.getDate() + calcdays + orgdue);
    retdate = moment(date).format('YYYY/MM/DD');
    return retdate;
  }
}

export function isValidDate(dt){
  var validDate = /^\d{4}\/\d{2}\/\d{2}$/;
  return validDate.test(dt);
}

function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export function ExchangerateRoundUp(customRate:string) {
  if (customRate != '' && customRate != undefined) {
    let b = customRate.split('.');
    let c = '0';
    if (b.length > 1) {
      if (b.length == 2 && b[1].length > 6) {
        if(Number(customRate)){
          c = Number(customRate).toFixed(6);
          return c;
        }
      }
      else if (b.length > 2){
        c = b[0]+'.'+ b[1];
        return c;
      }
      else{
        return customRate;
      }
    }
    else{
      return customRate;
    }
  }
}
export function  replaceNewline(params,field) {
  if (params.value != undefined && params.value != null && params.value != '') {
    let input = params.value;
    var newline =  String.fromCharCode(10);
    params.data[field] = params.data[field].replaceAll('\\n', newline);
    input = input.replaceAll('\\n', '<br>');
    return input.replaceAll(newline, '<br>');
  }
  else{
    return '';
  }
}

export function  changeDateTime_toOrgTimeZone(zoneValue)
{
  let localdate:any=new Date();
  // convert to msec
  // add local time zone offset
  // get UTC time in msec
  const utc= localdate.getTime() + (localdate.getTimezoneOffset() * 60000);
  let result:any= new Date(utc +(3600000* zoneValue.replace(':','.')));
  return moment(result).format('DD MMM,yyyy HH:mm');
}

export function returnDecimal(value, digits)
{
  return Number((Math.round(value*100)/100).toFixed(digits));
}

export function removingTrailingZeros(value: any): string{
  if(value.toString().includes('e')){
    value = value.toFixed(12);
    let dotIdx = value.indexOf(".") + 1;
    let decimalValues = value.slice(dotIdx, value.length);
    let result = "";
    
    for(let i = decimalValues.length - 1; i > 0; i--){
        if(decimalValues[i] == 0){
            if(result == "")
                result = value.slice(0, value.length - 1);
            else
                result = result.slice(0, result.length - 1);
        }
        else
            break;
    }
    return (result == "" ? value : result);
  }
  return value;
}

export function removeInvalidFileNameCharacters(fileName: string): string {
  const invalidFileChars: string[] = ["*", ":", "|", '"', "<", ">", "?", "/", "\\", "%"];
  invalidFileChars.forEach(invalidChar => {
    if (fileName.includes(invalidChar)) {
      fileName = fileName.replaceAll(invalidChar, '');
    }
  });
  return fileName.trim() + '.pdf';
}
export function zerobraceFormatter4(params) {
  let num;
  if (params.value > 0)
    num = Number(params.value).toFixed(4);
  else if (Number(params.value) == 0) {
    num = Number(params.value).toFixed(4);
  }
  else {
    num = "(" + Number(params.value).toFixed(4).replace("-", "") + ")";;
  }
  return num;
}

export function validDate(params) {
    if (!params) return null;
    let lstMonth = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    var todaydate = new Date();
    var startday = 1;
    var currentmonth = todaydate.getMonth() + 1;
    var currentyear = todaydate.getFullYear();
    const inputstartDate = params;
    var date: any = inputstartDate;
    var day = 0;
    var month = 0;
    var year = 0;
    var position1: any = 0;
    var position2: any = 0;
    var position3: any = 0;
    if (date) {
        date = date.replace(/[\s-,/.\.]/g, "~"); //whitespace-,/.
        date = date.replaceAll("~~", "~");
        date = date.replaceAll("~~", "~");
        date = date.replaceAll("~", "/");
        var lstdate = date.split("/");
        if (lstdate.length == 3) {
            //---------------Start ddmmyyyy--------------
            if (!isNaN(lstdate[0])) {
                //number day
                position1 = Number(lstdate[0]);
            }

            if (!isNaN(lstdate[1])) {
                //number month
                position2 = Number(lstdate[1]);
            } else if (isNaN(lstdate[1])) {
                //string
                position2 = lstMonth.findIndex((x) =>
                    x.toLowerCase().includes(lstdate[1].toLowerCase())
                );
                position2 = position2 + 1;
            }

            if (!isNaN(lstdate[2])) {
                //number year
                position3 = Number(lstdate[2]);
            }

            day = position1;
            month = position2;
            position3 =
                position3.toString().length == 1
                    ? "202" + position3.toString()
                    : position3.toString().length == 2
                    ? "20" + position3.toString()
                    : position3.toString().length == 3
                    ? "2" + position3.toString()
                    : position3;
            year = Number(position3.toString().substring(0, 4));

            date = new Date(year + "/" + month + "/" + day);
            //---------------End ddmmyyyy--------------
            //---------------Start mmddyyyy--------------
            if (date == "Invalid Date") {
                if (!isNaN(lstdate[1])) {
                    //number day
                    position1 = Number(lstdate[1]);
                }

                if (!isNaN(lstdate[0])) {
                    //number month
                    position2 = Number(lstdate[0]);
                } else if (isNaN(lstdate[0])) {
                    //string
                    position2 = lstMonth.findIndex((x) =>
                        x.toLowerCase().includes(lstdate[0].toLowerCase())
                    );
                    position2 = position2 + 1;
                }

                if (!isNaN(lstdate[2])) {
                    //number year
                    position3 = Number(lstdate[2]);
                }

                day = position1;
                month = position2;
                position3 =
                    position3.toString().length == 1
                        ? "202" + position3.toString()
                        : position3.toString().length == 2
                        ? "20" + position3.toString()
                        : position3.toString().length == 3
                        ? "2" + position3.toString()
                        : position3;
                year = Number(position3.toString().substring(0, 4));

                date = new Date(year + "/" + month + "/" + day);
                //---------------End mmddyyyy--------------
                //---------------Start yyyymmdd--------------
                if (date == "Invalid Date") {
                    if (!isNaN(lstdate[2])) {
                        //number day
                        position1 = Number(lstdate[2]);
                    }

                    if (!isNaN(lstdate[1])) {
                        //number month
                        position2 = Number(lstdate[1]);
                    } else if (isNaN(lstdate[1])) {
                        //string
                        position2 = lstMonth.findIndex((x) =>
                            x.toLowerCase().includes(lstdate[1].toLowerCase())
                        );
                        position2 = position2 + 1;
                    }

                    if (!isNaN(lstdate[0])) {
                        //number year
                        position3 = Number(lstdate[0]);
                    }

                    day = position1;
                    month = position2;
                    position3 =
                        position3.toString().length == 1
                            ? "202" + position3.toString()
                            : position3.toString().length == 2
                            ? "20" + position3.toString()
                            : position3.toString().length == 3
                            ? "2" + position3.toString()
                            : position3;
                    year = Number(position3.toString().substring(0, 4));

                    date = new Date(year + "/" + month + "/" + day);
                    //---------------End yyyymmdd--------------
                }
            }

            if (date == "Invalid Date") {
                day = startday;
                month = currentmonth;
                year = currentyear;
                date = new Date(year + "/" + month + "/" + day);
            }
        } else if (lstdate.length == 2) {
            //---------------Start ddmmyyyy--------------
            if (!isNaN(lstdate[0])) {
                //number day
                position1 = Number(lstdate[0]);
            }

            if (!isNaN(lstdate[1])) {
                //number month
                position2 = Number(lstdate[1]);
            } else if (isNaN(lstdate[1])) {
                //string
                position2 = lstMonth.findIndex((x) =>
                    x.toLowerCase().includes(lstdate[1].toLowerCase())
                );
                position2 = position2 + 1;
            }
            position3 = currentyear;

            day = position1;
            month = position2;
            year = position3;

            date = new Date(year + "/" + month + "/" + day);
            //---------------End ddmmyyyy--------------
            //---------------Start mmddyyyy--------------
            if (date == "Invalid Date") {
                if (!isNaN(lstdate[1])) {
                    //number day
                    position1 = Number(lstdate[1]);
                }

                if (!isNaN(lstdate[0])) {
                    //number month
                    position2 = Number(lstdate[0]);
                } else if (isNaN(lstdate[0])) {
                    //string
                    position2 = lstMonth.findIndex((x) =>
                        x.toLowerCase().includes(lstdate[0].toLowerCase())
                    );
                    position2 = position2 + 1;
                }

                position3 = currentyear;

                day = position1;
                month = position2;
                year = position3;

                date = new Date(year + "/" + month + "/" + day);
                //---------------End mmddyyyy--------------
                //---------------Start yyyymmdd--------------
                if (date == "Invalid Date") {
                    position1 = startday;

                    if (!isNaN(lstdate[1])) {
                        //number month
                        position2 = Number(lstdate[1]);
                    } else if (isNaN(lstdate[1])) {
                        //string
                        position2 = lstMonth.findIndex((x) =>
                            x.toLowerCase().includes(lstdate[1].toLowerCase())
                        );
                        position2 = position2 + 1;
                    }

                    if (!isNaN(lstdate[0])) {
                        //number year
                        position3 = Number(lstdate[0]);
                    }

                    day = position1;
                    month = position2;
                    position3 =
                        position3.toString().length == 1
                            ? "202" + position3.toString()
                            : position3.toString().length == 2
                            ? "20" + position3.toString()
                            : position3.toString().length == 3
                            ? "2" + position3.toString()
                            : position3;
                    year = Number(position3.toString().substring(0, 4));

                    date = new Date(year + "/" + month + "/" + day);
                    //---------------End yyyymmdd--------------
                }
            }

            if (date == "Invalid Date") {
                day = startday;
                month = currentmonth;
                year = currentyear;
                date = new Date(year + "/" + month + "/" + day);
            }
        } else if (lstdate.length == 1) {
            //---------------Start ddmmyyyy--------------
            if (!isNaN(lstdate[0])) {
                //number day
                position1 = Number(lstdate[0]);
            }
            position2 = currentmonth;
            position3 = currentyear;

            day = position1;
            month = position2;
            year = position3;

            date = new Date(year + "/" + month + "/" + day);
            //---------------End ddmmyyyy--------------
            //---------------Start mmddyyyy--------------
            if (date == "Invalid Date") {
                position1 = startday;

                if (!isNaN(lstdate[0])) {
                    //number month
                    position2 = Number(lstdate[0]);
                } else if (isNaN(lstdate[0])) {
                    //string
                    position2 = lstMonth.findIndex((x) =>
                        x.toLowerCase().includes(lstdate[0].toLowerCase())
                    );
                    position2 = position2 + 1;
                }

                position3 = currentyear;

                day = position1;
                month = position2;
                year = position3;

                date = new Date(year + "/" + month + "/" + day);
                //---------------End mmddyyyy--------------
                //---------------Start yyyymmdd--------------
                if (date == "Invalid Date") {
                    position1 = startday;
                    position2 = currentmonth;

                    if (!isNaN(lstdate[0])) {
                        //number year
                        position3 = Number(lstdate[0]);
                    }

                    day = position1;
                    month = position2;
                    position3 =
                        position3.toString().length == 1
                            ? "202" + position3.toString()
                            : position3.toString().length == 2
                            ? "20" + position3.toString()
                            : position3.toString().length == 3
                            ? "2" + position3.toString()
                            : position3;
                    year = Number(position3.toString().substring(0, 4));

                    date = new Date(year + "/" + month + "/" + day);
                    //---------------End yyyymmdd--------------
                }
            }

            if (date == "Invalid Date") {
                day = startday;
                month = currentmonth;
                year = currentyear;
                date = new Date(year + "/" + month + "/" + day);
            }
        } else {
            day = startday;
            month = currentmonth;
            year = currentyear;
            date = new Date(year + "/" + month + "/" + day);
        }
    } else {
        day = startday;
        month = currentmonth;
        year = currentyear;
        date = new Date(year + "/" + month + "/" + day);
    }

    if (new Date(date) < new Date("01/01/1990")) {
        day = 1;
        month = 1;
        year = 1990;
        date = new Date(year + "/" + month + "/" + day);
    }
    const dt = new DatePipe('en-US');
    let finaldate=dt.transform(date, "yyyy/MM/dd").toString();
    return finaldate
}

declare global {
  interface Number {
    /**
     * Round to decimal as Database, Eg: decimal = 2, this = 8.555 then return 8.56
     * @param decimal Decimal count*/
    round(decimal?: number): String;
  }
}
 
Number.prototype.round = function (decimal) {
  let tense = Math.pow(10, decimal??0);
  return (Math.round((Number(this) * tense)) / tense).toString();
};
