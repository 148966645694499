import { Injectable } from '@angular/core';
import { MasterServices } from '../_services/master.service';
import { returnDecimal } from '../_common/commonfunctions';
import { CalculationService } from "../_common/calculation.service";
@Injectable({
  providedIn: 'root'
})

export class TaxCalculationServices {
  lstTaxType: any = [];
  lstTaxRateMaster: any = [];
  lsttaxCompoundRate: any = [];
  rtnDCObj: any;
  rtnObj: any;
  rtntaxObj: any;

  constructor(private master: MasterServices, private calcServices: CalculationService) {
    this.rtnDCObj = {
      subDebitTotal: 0.00,
      subCreditTotal: 0.00,
      taxList: [],
      debitTotalTax: 0.00,
      creditTotalTax: 0.00,
      debitTotal: 0.00,
      creditTotal: 0.00,
      totalOutBy: 0.00,
    }
    this.rtnObj = {
      subTotal: 0.00,
      taxAmountsAreList: [],
      LinetaxtotalAmountsAreList: [],
      totalTax: 0.00,
      total: 0.00,
      TotalTaxByTaxID: 0.00
    }
    this.rtntaxObj = {
      taxAmountsList: [],
    }


    this.getTaxType();
    this.GetTaxRateMaster();
    this.getTaxCompoundRate();
  }

  async getTaxType() {
    this.lstTaxType = await this.master.getTaxType();
  }
  async GetTaxRateMaster() {
    this.lstTaxRateMaster = await this.master.GetTaxRateMaster();
  }

  async getTaxCompoundRate() {
    this.lsttaxCompoundRate = await this.master.getTaxCompoundRate();
  }


  onTotalDebitCreditTaxCalculation(taxTypeID: number, rowData: any, debitTaxAdjustment: any, creditTaxAdjustment: any) {
    let selectTax = this.lstTaxType.filter(x => x.taxTypeID == taxTypeID);
    if (selectTax?.length > 0) {
      let taxTypeName = selectTax[0].taxTypeName;
      let debitTotal = 0.00;
      let creditTotal = 0.00;
      let debitTotalProp = 0.00;
      let creditTotalProp = 0.00;
      let findDebitTaxRate = 0.00;
      let findCreditTaxRate = 0.00;
      let taxList = [];
      debitTaxAdjustment = isNaN(Number(debitTaxAdjustment)) ? 0 : Number(debitTaxAdjustment);
      creditTaxAdjustment = isNaN(Number(creditTaxAdjustment)) ? 0 : Number(creditTaxAdjustment);

      rowData.forEach((row: any, index) => {
        if (row.taxRateID > 0) {
          let taxrateObj = this.lsttaxCompoundRate.filter(x => x.taxRateID == row.taxRateID);
          debitTotal = this.calcServices.calcFunction(Number(debitTotal), Number(row.debit), '+');
          creditTotal = this.calcServices.calcFunction(Number(creditTotal), Number(row.credit), '+');
          if (taxrateObj[0].rate > 0) {
            debitTotalProp = this.calcServices.calcFunction(Number(debitTotalProp), Number(row.debit), '+');
            creditTotalProp = this.calcServices.calcFunction(Number(creditTotalProp), Number(row.credit), '+');
          }

          if (taxTypeName == 'No Tax') {
            row.debitTax = 0.00;
            row.creditTax = 0.00;
            row.taxAmount = 0.00;
          }
        }
      });

      let uniqueTaxID = [];
      let debitAdjustedTax = 0;
      let creditAdjustedTax = 0;
      
      if (taxTypeName != 'No Tax') {
        rowData.forEach((row: any, index) => {
          if (Number(row.taxRateID) > 0) {
            row.debitTax = 0;
            row.creditTax = 0;
            row.taxAmount = 0;

            let taxrateObj: any = this.lsttaxCompoundRate.filter(x => x.taxRateID == row.taxRateID);

            let taxRate = Number(taxrateObj[0].effectiveRate) > 0 ? taxrateObj[0].effectiveRate : taxrateObj[0].taxRate;
            let debittax = 0.00;
            let creditax = 0.00;

            let debitb = 0.00;
            let creditb = 0.00;
            if (Number(taxRate) > 0) {
              if (taxTypeName == 'Tax Exclusive') {
                if (debitTotalProp > 0) {
                  let debita = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(row.debit), Number(debitTotalProp), '/')), Number(debitTaxAdjustment), '*');
                  debitb = Math.round((Number(debita) / 1) * 100) / 100;
                }
                debittax = this.calcServices.calcFunction(this.calcServices.calcFunction((Number(row.debit) / 100), Number(taxRate), '*'), Number(debitb), '+');
                if (creditTotalProp > 0) {
                  let credita = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(row.credit), Number(creditTotalProp), '/')), Number(creditTaxAdjustment), '*');
                  creditb = Math.round((Number(credita) / 1) * 100) / 100;
                }
                creditax = this.calcServices.calcFunction(this.calcServices.calcFunction((Number(row.credit) / 100), Number(taxRate), '*'), Number(creditb), '+');
              }
              else if (selectTax[0].taxTypeName == 'Tax Inclusive') {
                debittax = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(row.debit), (100 + Number(taxRate)), '/')), Number(taxRate), '*');
                creditax = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(row.credit), (100 + Number(taxRate)), '/')), Number(taxRate), '*');
              }
            }
            row.debitTax = Math.round(debittax * 100) / 100;
            row.creditTax = Math.round(creditax * 100) / 100;
            row.taxAmount = Number(row.debitTax) > 0 ? Math.round(Number(row.debitTax) * 100) / 100 : Math.round(Number(row.creditTax) * 100) / 100;

            findDebitTaxRate = this.calcServices.calcFunction(Number(findDebitTaxRate), Number(row.debitTax), '+');
            findCreditTaxRate = this.calcServices.calcFunction(Number(findCreditTaxRate), Number(row.creditTax), '+');

            const exists = uniqueTaxID.findIndex(element => element.taxRateID === row.taxRateID) > -1;
            if (!exists) {
              uniqueTaxID.push({ taxRateID: row.taxRateID, taxRateName: taxrateObj[0].taxName, taxRate: taxrateObj[0].taxRate, debitTax: row.debitTax, creditTax: row.creditTax });
            }
            else if (exists) {
              let index = uniqueTaxID.findIndex(x => x.taxRateID == row.taxRateID)
              uniqueTaxID[index].debitTax += row.debitTax;
              uniqueTaxID[index].creditTax += row.creditTax;
            }


            for (let i = 0; i < taxrateObj.length; i++) {
              let ComptaxRate = taxrateObj[i].taxCalculationRate;

              let caldebitTaxrate = 0.00;
              if (debittax != 0) {
                if (taxRate != 0) {
                  caldebitTaxrate = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(debittax), Number(taxRate), '/')), Number(ComptaxRate), '*');
                  let compDebit = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(debitb), Number(taxRate), '/')), Number(ComptaxRate), '*');
                  debitAdjustedTax = Math.round(debitAdjustedTax * 100) / 100 + Math.round(compDebit * 100) / 100;
                }
                else{
                  caldebitTaxrate = Number(debittax);
                }
              }
              let calcreditTaxrate = 0.00;
              if (creditax != 0) {
                if (taxRate != 0){
                  calcreditTaxrate = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(creditax), Number(taxRate), '/')), Number(ComptaxRate), '*');
                  let compCredit = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(creditb), Number(taxRate), '/')), Number(ComptaxRate), '*');
                  creditAdjustedTax = Math.round(creditAdjustedTax * 100) / 100 + Math.round(compCredit * 100) / 100;
                }
                else{
                  calcreditTaxrate = Number(creditax);
                }
              }

              let taxType = {
                index: index,
                taxRateID: row.taxRateID,
                taxCompoundID: taxrateObj[i].taxCompoundID,
                taxRate: ComptaxRate,
                taxDebitcalAmt: Math.round(caldebitTaxrate * 100) / 100,
                taxCreditcalAmt: Math.round(calcreditTaxrate * 100) / 100,
                taxType: taxrateObj[i].compoundName
              };
              taxList.push(taxType);
            }
          }
        });
      }

      let newtaxList = [];
      uniqueTaxID.forEach(element => {
        let a = taxList.filter(x => x.taxRateID == element.taxRateID);
        var result = [];
        a.reduce(function (res, value) {
          if (!res[value.taxCompoundID]) {
            res[value.taxCompoundID] = { taxCompoundID: value.taxCompoundID, taxDebitcalAmt: 0, taxCreditcalAmt: 0 };
            result.push(res[value.taxCompoundID])
          }
          res[value.taxCompoundID].taxRate = value.taxRate;
          res[value.taxCompoundID].taxType = value.taxType;
          res[value.taxCompoundID].taxDebitcalAmt += value.taxDebitcalAmt;
          res[value.taxCompoundID].taxCreditcalAmt += value.taxCreditcalAmt;
          return res;
        }, {});

        let Obj = {
          taxRateID: element.taxRateID,
          taxRateName: element.taxRateName,
          taxRate: element.taxRate,
          debitTax: element.debitTax,
          creditTax: element.creditTax,
          taxCompoundRate: result
        }
        newtaxList.push(Obj);
      });

      let debitAdjustmentDiff = Math.round(Number(debitTaxAdjustment) * 100 - Number(debitAdjustedTax) * 100) / 100
      let creditAdjustmentDiff = Math.round(Number(creditTaxAdjustment) * 100 - Number(creditAdjustedTax) * 100) / 100
      findDebitTaxRate = this.jounalManualAdjustment('debitTax', findDebitTaxRate, rowData, debitAdjustmentDiff, newtaxList, taxTypeName);
      findCreditTaxRate = this.jounalManualAdjustment('creditTax', findCreditTaxRate, rowData, creditAdjustmentDiff, newtaxList, taxTypeName);

      this.rtnDCObj.subDebitTotal = Math.round(Number(debitTotal) * 100) / 100;
      this.rtnDCObj.subCreditTotal = Math.round(Number(creditTotal) * 100) / 100;
      if (selectTax[0].taxTypeName == 'Tax Exclusive') {
        this.rtnDCObj.taxList = newtaxList;

        this.rtnDCObj.debitTotalTax = Math.round(Number(findDebitTaxRate) * 100) / 100;
        this.rtnDCObj.creditTotalTax = Math.round(Number(findCreditTaxRate) * 100) / 100;

        this.rtnDCObj.debitTotal = Math.round((Number(debitTotal) + Number(findDebitTaxRate)) * 100) / 100;
        this.rtnDCObj.creditTotal = Math.round((Number(creditTotal) + Number(findCreditTaxRate)) * 100) / 100;
      }
      else if (selectTax[0].taxTypeName == 'Tax Inclusive') {
        this.rtnDCObj.taxList = newtaxList;

        this.rtnDCObj.debitTotalTax = Math.round(Number(findDebitTaxRate) * 100) / 100;
        this.rtnDCObj.creditTotalTax = Math.round(Number(findCreditTaxRate) * 100) / 100;

        this.rtnDCObj.debitTotal = this.rtnDCObj.subDebitTotal;
        this.rtnDCObj.creditTotal = this.rtnDCObj.subCreditTotal;

        this.rtnDCObj.subDebitTotal = Math.round((this.calcServices.calcFunction(this.rtnDCObj.subDebitTotal, this.rtnDCObj.debitTotalTax, '-')) * 100) / 100;
        this.rtnDCObj.subCreditTotal = Math.round((this.calcServices.calcFunction(this.rtnDCObj.subCreditTotal, this.rtnDCObj.creditTotalTax, '-')) * 100) / 100;
      }
      else if (selectTax[0].taxTypeName == 'No Tax') {
        this.rtnDCObj.taxList = [];
        this.rtnDCObj.debitTotalTax = 0;
        this.rtnDCObj.creditTotalTax = 0;

        this.rtnDCObj.debitTotal = this.rtnDCObj.subDebitTotal;
        this.rtnDCObj.creditTotal = this.rtnDCObj.subCreditTotal;
      }
    }
    return this.rtnDCObj;
  }
  
  onTotalDebitCreditTaxCalculationForLoad(taxTypeID: number, rowData: any, debitTaxAdjustment: any, creditTaxAdjustment: any, IsNewViewScreen = false) {
    let selectTax = this.lstTaxType.filter(x => x.taxTypeID == taxTypeID);
    if (selectTax?.length > 0) {
      let taxTypeName = selectTax[0].taxTypeName;
      let debitTotal = 0.00;
      let creditTotal = 0.00;
      let findDebitTaxRate = 0.00;
      let findCreditTaxRate = 0.00;
      let taxList = [];
      debitTaxAdjustment = isNaN(Number(debitTaxAdjustment)) ? 0 : Number(debitTaxAdjustment);
      creditTaxAdjustment = isNaN(Number(creditTaxAdjustment)) ? 0 : Number(creditTaxAdjustment);

      rowData.forEach((row: any, index) => {
        if (row.taxRateID > 0) {
          let taxrateObj = this.lsttaxCompoundRate.filter(x => x.taxRateID == row.taxRateID);
          debitTotal = this.calcServices.calcFunction(Number(debitTotal), Number(row.debit), '+');
          creditTotal = this.calcServices.calcFunction(Number(creditTotal), Number(row.credit), '+');

          if (taxTypeName == 'No Tax') {
            row.debitTax = 0.00;
            row.creditTax = 0.00;
            row.taxAmount = 0.00;
          }
        }
      });

      let uniqueTaxID = [];
      if (taxTypeName != 'No Tax') {
        rowData.forEach((row: any, index) => {
          if (Number(row.taxRateID) > 0) {
            let taxrateObj: any = this.lsttaxCompoundRate.filter(x => x.taxRateID == row.taxRateID);

            let taxRate = Number(taxrateObj[0].effectiveRate) > 0 ? taxrateObj[0].effectiveRate : taxrateObj[0].taxRate;
            let debittax = 0.00;
            let creditax = 0.00;

            if (Number(taxRate) > 0) {

              debittax = isNaN(Number(row.debitTax)) ? 0 : Number(row.debitTax);
              creditax = isNaN(Number(row.creditTax)) ? 0 : Number(row.creditTax);
            }
            row.debitTax = debittax;
            row.creditTax = creditax;
            
            //Find total tax amount
            findDebitTaxRate = this.calcServices.calcFunction(Number(findDebitTaxRate), Number(row.debitTax), '+');
            findCreditTaxRate = this.calcServices.calcFunction(Number(findCreditTaxRate), Number(row.creditTax), '+');

            const exists = uniqueTaxID.findIndex(element => element.taxRateID === row.taxRateID) > -1;
            if (!exists) {
              uniqueTaxID.push({ taxRateID: row.taxRateID, taxRateName: taxrateObj[0].taxName, taxRate: taxrateObj[0].taxRate, debitTax: row.debitTax, creditTax: row.creditTax });
            }
            else if (exists) {
              let index = uniqueTaxID.findIndex(x => x.taxRateID == row.taxRateID)
              uniqueTaxID[index].debitTax += row.debitTax;
              uniqueTaxID[index].creditTax += row.creditTax;
            }

            for (let i = 0; i < taxrateObj.length; i++) {
              let ComptaxRate = taxrateObj[i].taxCalculationRate;

              let caldebitTaxrate = 0.00;
              if (debittax != 0) {
                if (taxRate != 0) {
                  caldebitTaxrate = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(debittax), Number(taxRate), '/')), Number(ComptaxRate), '*');
                }
                else{
                  caldebitTaxrate = Number(debittax);
                }
              }
              let calcreditTaxrate = 0.00;
              if (creditax != 0) {
                if (taxRate != 0){
                  calcreditTaxrate = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(creditax), Number(taxRate), '/')), Number(ComptaxRate), '*');
                }
                else{
                  calcreditTaxrate = Number(creditax);
                }
              }

              let taxType = {
                index: index,
                taxRateID: row.taxRateID,
                taxCompoundID: taxrateObj[i].taxCompoundID,
                taxRate: ComptaxRate,
                taxDebitcalAmt: Math.round(caldebitTaxrate * 100) / 100,
                taxCreditcalAmt: Math.round(calcreditTaxrate * 100) / 100,
                taxType: taxrateObj[i].compoundName
              };
              taxList.push(taxType);
            }
          }
        });
      }

      let newtaxList = [];
      uniqueTaxID.forEach(element => {
        let a = taxList.filter(x => x.taxRateID == element.taxRateID);
        var result = [];
        a.reduce(function (res, value) {
          if (!res[value.taxCompoundID]) {
            res[value.taxCompoundID] = { taxCompoundID: value.taxCompoundID, taxDebitcalAmt: 0, taxCreditcalAmt: 0 };
            result.push(res[value.taxCompoundID])
          }
          res[value.taxCompoundID].taxRate = value.taxRate;
          res[value.taxCompoundID].taxType = value.taxType;
          res[value.taxCompoundID].taxDebitcalAmt += value.taxDebitcalAmt;
          res[value.taxCompoundID].taxCreditcalAmt += value.taxCreditcalAmt;
          return res;
        }, {});

        let Obj = {
          taxRateID: element.taxRateID,
          taxRateName: element.taxRateName,
          taxRate: element.taxRate,
          debitTax: element.debitTax,
          creditTax: element.creditTax,
          taxCompoundRate: result
        }
        newtaxList.push(Obj);
      });

      this.rtnDCObj.subDebitTotal = Math.round(Number(debitTotal) * 100) / 100;
      this.rtnDCObj.subCreditTotal = Math.round(Number(creditTotal) * 100) / 100;
      if ((selectTax[0].taxTypeName == 'Tax Exclusive')){
        this.rtnDCObj.taxList = newtaxList;

        this.rtnDCObj.debitTotalTax = Math.round(Number(findDebitTaxRate) * 100) / 100;
        this.rtnDCObj.creditTotalTax = Math.round(Number(findCreditTaxRate) * 100) / 100;

        this.rtnDCObj.debitTotal = Math.round((Number(debitTotal) + Number(findDebitTaxRate)) * 100) / 100;
        this.rtnDCObj.creditTotal = Math.round((Number(creditTotal) + Number(findCreditTaxRate)) * 100) / 100;
      }
      else if (selectTax[0].taxTypeName == 'Tax Inclusive') {
        this.rtnDCObj.taxList = newtaxList;

        this.rtnDCObj.debitTotalTax = Math.round(Number(findDebitTaxRate) * 100) / 100;
        this.rtnDCObj.creditTotalTax = Math.round(Number(findCreditTaxRate) * 100) / 100;

        this.rtnDCObj.debitTotal = this.rtnDCObj.subDebitTotal;
        this.rtnDCObj.creditTotal = this.rtnDCObj.subCreditTotal;

        if (!IsNewViewScreen) {
          this.rtnDCObj.subDebitTotal = Math.round((this.calcServices.calcFunction(this.rtnDCObj.subDebitTotal, this.rtnDCObj.debitTotalTax, '-')) * 100) / 100;
          this.rtnDCObj.subCreditTotal = Math.round((this.calcServices.calcFunction(this.rtnDCObj.subCreditTotal, this.rtnDCObj.creditTotalTax, '-')) * 100) / 100;
        }
      }
      else if (selectTax[0].taxTypeName == 'No Tax') {
        this.rtnDCObj.taxList = [];
        this.rtnDCObj.debitTotalTax = 0;
        this.rtnDCObj.creditTotalTax = 0;

        this.rtnDCObj.debitTotal = this.rtnDCObj.subDebitTotal;
        this.rtnDCObj.creditTotal = this.rtnDCObj.subCreditTotal;
      }
    }
    return this.rtnDCObj;
  }

  onTotalTaxCalculation(taxTypeID: number, rowData: any, TaxAdjustment?: any, mode: string = "") {

    let allrowTotal = 0;
    let findTaxRate = 0;
    let taxrateObj = [];
    let totalLineTaxAmount = 0;
    let totalTaxAmount = 0;
    let calTaxrate = 0;
    let totalTaxAmountList: any = [];
    let taxType: any;
    let totallinetaxAmountsList: any = [];

    let taxCompoundList = [];
    let linetaxAmountList: any = [];

    let selectTax = this.lstTaxType.filter(x => x.taxTypeID == taxTypeID);
    rowData.forEach((row: any, index) => {
      // if (row.TaxID > 0) {
      allrowTotal = this.calcServices.calcFunction(Number(allrowTotal), Number(row.Amount), '+');
      // }
    });

    if (selectTax[0].taxTypeName != 'No Tax') {

      rowData.forEach((row: any, index) => {
        let txr = 0;
        if (row.TaxID !== null && row.TaxID !== "" && row.TaxID !== 0) {
          taxrateObj = this.lsttaxCompoundRate.filter(x => x.taxRateID == row.TaxID);

          if (taxrateObj.length > 0) {
            txr = taxrateObj[0].taxCalculationRate;
            for (let i = 0; i <= taxrateObj.length - 1; i++) {
              txr = taxrateObj[i].taxCalculationRate;

              if (selectTax[0].taxTypeName == 'Tax Exclusive') {
                calTaxrate = this.calcServices.calcFunction(Number(row.Amount), Number(txr), '*') / 100;
                if (calTaxrate != 0) {
                  let a = (allrowTotal !== 0 ? this.calcServices.calcFunction((this.calcServices.calcFunction(Number(row.Amount), Number(allrowTotal), '/')), Number(TaxAdjustment), '*') : 0);
                  let b = Number(a) / Number(taxrateObj.length);
                  calTaxrate = Math.round((this.calcServices.calcFunction(Number(calTaxrate), Number(b), '+')) * 100) / 100;
                }
                else {
                  if (Number(TaxAdjustment) != 0) {
                    let a = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(row.Amount), Number(allrowTotal), '/')), Number(TaxAdjustment), '*');
                    let b = this.calcServices.calcFunction(Number(a), Number(taxrateObj.length), '/');
                    calTaxrate = Math.round((this.calcServices.calcFunction(Number(calTaxrate), Number(b), '+')) * 100) / 100;
                  }
                }
                row.taxAmount = Number(row.Amount) != 0 ? Math.round(Number(row.Amount) * 100) / 100 : 0;
                findTaxRate = this.calcServices.calcFunction(Number(findTaxRate), Number(calTaxrate), '+');
                //add tax compound rate(3)
                taxType =
                {
                  index: index,
                  taxRate: txr,
                  taxCompoundAmt: Math.round(calTaxrate * 100) / 100,
                  taxType: "Total " + taxrateObj[i].compoundName,
                  taxID: row.TaxID
                };
                taxCompoundList.push(taxType);
              }
              else if (selectTax[0].taxTypeName == 'Tax Inclusive') {
                calTaxrate = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(row.Amount), (100 + Number(txr)), '/')), Number(txr), '*');
                findTaxRate = 0;
                //add tax compound rate
                taxType =
                {
                  index: index,
                  taxRate: txr,
                  taxCompoundAmt: Math.round(calTaxrate * 100) / 100,
                  taxType: "Includes " + taxrateObj[i].compoundName,
                  taxID: row.TaxID
                };
                taxCompoundList.push(taxType);
              }
              //total line item tax amount (sum of tax compound rate)
              totalLineTaxAmount = totalLineTaxAmount + Math.round(calTaxrate * 100) / 100;
            }
            //add line tax amount for grid
            let linetaxamount =
            {
              index: index,
              taxType: taxType.taxType,
              taxcalAmt: totalLineTaxAmount,
            };
            linetaxAmountList.push(linetaxamount);

            //add line tax amount woth compound rate for total tax section(2)
            let totallinetax =
            {
              index: index,
              taxID: row.TaxID,
              taxType: taxrateObj[0].taxName + "  " + taxrateObj[0].taxRate + "%",
              linetaxAmount: totalLineTaxAmount,
              taxCompoundAmountList: taxrateObj.length > 1 ? Object.assign([], taxCompoundList.filter(x => x.index == index)) : "",
            };
            //sum same taxid's compund rate and tax amount
            if (totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID).length > 0) {
              totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].linetaxAmount =
                totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].linetaxAmount +
                totallinetax.linetaxAmount;
              for (let k = 0; k <= totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].taxCompoundAmountList.length - 1; k++) {
                totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].taxCompoundAmountList[k].taxCompoundAmt =
                  totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].taxCompoundAmountList[k].taxCompoundAmt +
                  totallinetax.taxCompoundAmountList[k].taxCompoundAmt;
                if (taxTypeID == 1 || mode == "view") {
                  totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].taxCompoundAmountList[k].taxType =
                    totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].taxCompoundAmountList[k].taxType.replace('Includes', 'Total');
                }
              }
            }
            else {
              if (taxTypeID == 1 || mode == "view") {
                for (let k = 0; k <= totallinetax.taxCompoundAmountList.length - 1; k++) {
                  totallinetax.taxCompoundAmountList[k].taxType =
                    totallinetax.taxCompoundAmountList[k].taxType.replace('Includes', 'Total');
                }
              }
              totallinetaxAmountsList.push(totallinetax);
            }
            totalTaxAmount = this.calcServices.calcFunction(totalTaxAmount, totalLineTaxAmount, '+');
            totalLineTaxAmount = 0;
          }
        }
      });
    }
    else {
      rowData.forEach((row: any, index) => {
        if (selectTax[0].taxTypeName == 'No Tax') {
          row.taxAmount = 0;
        }
      });
    }


    this.rtnObj.subTotal = Math.round(allrowTotal * 100) / 100;
    if (selectTax[0].taxTypeName === 'Tax Exclusive') {
      this.rtnObj.total = Math.round((this.calcServices.calcFunction(Number(allrowTotal), Number(findTaxRate), '+')) * 100) / 100;
    } else if (selectTax[0].taxTypeName === "No Tax") {
      this.rtnObj.total = this.rtnObj.subTotal
    } else {
      this.rtnObj.total = this.rtnObj.subTotal
    }

    //add total tax amount(1)
    let totalTaxAmountitems =
    {
      taxType: "Total Tax Amount",
      totalTaxAmount: totalTaxAmount,
      totallinetaxAmountsList: totallinetaxAmountsList
    };
    totalTaxAmountList.push(totalTaxAmountitems);

    this.rtnObj.taxAmountsAreList = linetaxAmountList;
    this.rtnObj.LinetaxtotalAmountsAreList = totalTaxAmountList;
    return this.rtnObj;
  }

  onTotalTaxCalculationOld(taxTypeID: number, rowData: any, TaxAdjustment?: any) {

    let allrowTotal = 0;
    let findTaxRate = 0;
    let taxrateObj = [];
    let totalLineTaxAmount = 0;
    let totalTaxAmount = 0;
    let calTaxrate = 0;
    let CtaxAmountsAreList: any = [];
    let totalTaxAmountList: any = [];
    let taxType: any;
    let totallinetaxAmountsList: any = [];


    let selectTax = this.lstTaxType.filter(x => x.taxTypeID == taxTypeID);
    rowData.forEach((row: any, index) => {
      // if (row.TaxID > 0) {
      allrowTotal = this.calcServices.calcFunction(Number(allrowTotal), Number(row.Amount), '+');
      // }
    });

    if (selectTax[0].taxTypeName != 'No Tax') {

      rowData.forEach((row: any, index) => {
        let txr = 0;
        if (row.TaxID !== null && row.TaxID !== "" && row.TaxID !== 0) {
          taxrateObj = this.lsttaxCompoundRate.filter(x => x.taxRateID == row.TaxID);
          if (taxrateObj.length > 0) {
            txr = taxrateObj[0].taxCalculationRate;
            for (let i = 0; i <= taxrateObj.length - 1; i++) {
              txr = taxrateObj[i].taxCalculationRate;
              if (selectTax[0].taxTypeName == 'Tax Exclusive') {
                calTaxrate = this.calcServices.calcFunction(Number(row.Amount), Number(txr), '*') / 100;
                if (calTaxrate != 0) {
                  let a = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(row.Amount), Number(allrowTotal), '/')), Number(TaxAdjustment), '*');
                  let b = this.calcServices.calcFunction(Number(a), Number(taxrateObj.length), '/');
                  calTaxrate = Math.round((this.calcServices.calcFunction(Number(calTaxrate), Number(b), '+')) * 100) / 100;
                }
                else {
                  if (Number(TaxAdjustment) != 0) {
                    let a = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(row.Amount), Number(allrowTotal), '/')), Number(TaxAdjustment), '*');
                    let b = this.calcServices.calcFunction(Number(a), Number(taxrateObj.length), '/');
                    calTaxrate = Math.round((this.calcServices.calcFunction(Number(calTaxrate), Number(b), '+')) * 100) / 100;
                  }
                }
                row.taxAmount = Number(row.Amount) != 0 ? Math.round(Number(row.Amount) * 100) / 100 : 0;
                findTaxRate = this.calcServices.calcFunction(Number(findTaxRate), Number(calTaxrate), '+');
                //add tax compound rate
                taxType =
                {
                  index: index,
                  taxRate: txr,
                  taxcalAmt: Math.round(calTaxrate * 100) / 100,
                  taxType: "Total " + taxrateObj[i].compoundName,
                  taxID: row.TaxID
                };
                CtaxAmountsAreList.push(taxType);
              }
              else if (selectTax[0].taxTypeName == 'Tax Inclusive') {
                calTaxrate = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(row.Amount), (100 + Number(txr)), '/')), Number(txr), '*');
                findTaxRate = 0;
                //add tax compound rate
                taxType =
                {
                  index: index,
                  taxRate: txr,
                  taxcalAmt: Math.round(calTaxrate * 100) / 100,
                  taxType: "Includes " + taxrateObj[i].compoundName,
                  taxID: row.TaxID
                };
                CtaxAmountsAreList.push(taxType);
              }
              totalLineTaxAmount = this.calcServices.calcFunction(totalLineTaxAmount, Math.round(calTaxrate * 100), '+') / 100;
            }
            //add line tax amount
            let totallinetax =
            {
              index: index,
              taxID: row.TaxID,
              taxType: taxrateObj[0].taxName + "  " + taxrateObj[0].taxRate + "%",
              linetaxAmount: totalLineTaxAmount,
              taxAmountsAreList: taxrateObj.length > 1 ? Object.assign([], CtaxAmountsAreList.filter(x => x.index == index)) : '',
              //taxAmountsAreList1:[]
            };
            totallinetaxAmountsList.push(totallinetax);
            totalTaxAmount = this.calcServices.calcFunction(totalTaxAmount, totalLineTaxAmount, '+');
            totalLineTaxAmount = 0;
          }
        }
      });
    }
    else {
      rowData.forEach((row: any, index) => {
        if (selectTax[0].taxTypeName == 'No Tax') {
          row.taxAmount = 0;
        }
      });
    }

    this.rtnObj.subTotal = Math.round(allrowTotal * 100) / 100;
    if (selectTax[0].taxTypeName === 'Tax Exclusive') {
      this.rtnObj.total = Math.round((this.calcServices.calcFunction(Number(allrowTotal), Number(findTaxRate), '+')) * 100) / 100;
    } else if (selectTax[0].taxTypeName === "No Tax") {
      this.rtnObj.total = this.rtnObj.subTotal
    } else {
      this.rtnObj.total = this.rtnObj.subTotal
    }

    //add total tax amount
    let totalTaxAmountitems =
    {
      taxType: "Total Tax Amount",
      totalTaxAmount: totalTaxAmount,
      totallinetaxAmountsList: totallinetaxAmountsList
    };
    totalTaxAmountList.push(totalTaxAmountitems);
    // const tempMyObj = Object.assign({}, this.test);
    // const obj2:any = Object.create(this.test);
    //this.rtnObj.taxAmountsAreList= Object.assign({},CtaxAmountsAreList);
    this.rtntaxObj.taxAmountsList = Object.assign({}, CtaxAmountsAreList);

    const groupTax = totalTaxAmountList[0].totallinetaxAmountsList;
    totalTaxAmountList[0].totallinetaxAmountsList = [];
    const result: any = [];
    result.push(totalTaxAmountList[0]);
    this.sumSameTaxIDAmount(result, groupTax);

    let temp: any = [];
    temp = this.converttaxArraytoList(this.rtntaxObj.taxAmountsList);
    this.rtntaxObj.taxAmountsList = temp;
    return this.rtnObj;
  }

  sumSameTaxIDAmount(result: any, groupTax: any) {
    let compoundTax: any = [];
    let fresult: any = [];
    fresult = result[0].totallinetaxAmountsList;
    for (let i = 0; i <= groupTax.length - 1; i++) {
      if (this.checkTaxRateExists(fresult, groupTax[i].taxID)) {
        fresult[fresult.findIndex(x => x.taxID == groupTax[i].taxID)].linetaxAmount = fresult[fresult.findIndex(x => x.taxID == groupTax[i].taxID)].linetaxAmount + groupTax[i].linetaxAmount;
        //  let temp:any=[];
        //  temp =Object.assign([],this.converttaxArraytoList(this.converttaxArraytoList(fresult[fresult.findIndex(x => x.taxID == groupTax[i].taxID)].taxAmountsAreList)));
        //  if(temp.length>0)
        //  {
        for (let k = 0; k <= fresult[fresult.findIndex(x => x.taxID == groupTax[i].taxID)].taxAmountsAreList.length - 1; k++) {
          ;
          fresult[fresult.findIndex(x => x.taxID == groupTax[i].taxID)].taxAmountsAreList[k].taxcalAmt
          //need to chcek
          = this.calcServices.calcFunction(fresult[fresult.findIndex(x => x.taxID == groupTax[i].taxID)].taxAmountsAreList[k].taxcalAmt, groupTax[i].taxAmountsAreList[k].taxcalAmt, '+');
        }
        //  }
      }
      else {
        let tax = groupTax[i];
        let temp: any = [];
        temp = this.converttaxArraytoList(tax.taxAmountsAreList);
        // compoundTax =Object.assign([],temp);
        tax.taxAmountsAreList = "";
        tax.taxAmountsAreList = temp;
        fresult.push(tax);
      }
    }
    result[0].totallinetaxAmountsList = fresult;
    //this.rtnObj.LinetaxtotalAmountsAreList=Object.create(result);
    this.rtnObj.LinetaxtotalAmountsAreList = result;
  }

  checkTaxRateExists(result: any, taxID: number): boolean {
    if (result.filter(x => x.taxID === taxID).length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  converttaxArraytoList(temp: any) {
    let result: any = [];
    Object.keys(temp).map((key, index) => {
      result.push(temp[index]);
    });
    return result;
  }


  onTaxCalculation(taxTypeName: string, rowData: any, taxAdjustment?: any, mode: string = "") {
    let allrowTotal = 0;
    let allrowTotal_withtax = 0;
    let allrowTotal_tax = 0;
    let findTaxRate = 0;
    let taxrateObj = [];
    let totalLineTaxAmount = 0;
    let totalTaxAmount = 0;
    let calTaxrate = 0;
    let totalTaxAmountList: any = [];
    let taxType: any;
    let totallinetaxAmountsList: any = [];

    let adjustedtax = 0;

    taxAdjustment = isNaN(Number(taxAdjustment)) ? 0 : Number(taxAdjustment);

    let taxCompoundList = [];
    let linetaxAmountList: any = [];

    //let selectTax = this.lstTaxType.filter(x => x.taxTypeID == taxTypeID);

    rowData.forEach((row: any) => {
      let taxRatevalueobj = this.lsttaxCompoundRate.filter(m => m.taxRateID == row.taxRateID);
      allrowTotal = Number(allrowTotal) + Number(row.amount);
      allrowTotal_withtax = Number(allrowTotal_withtax) + (Number(taxRatevalueobj[0]?.rate) != 0 && row.isGroup != true ? Number(row.amount) : 0);
      //allrowTotal_tax = Number(allrowTotal_tax) + (Number(taxRatevalue) != 0 && row.isGroup != true ? Number(row.taxAmount) : 0);
      for (let i = 0; i <= taxRatevalueobj.length - 1; i++) {
        allrowTotal_tax = this.calcServices.calcFunction(Number(allrowTotal_tax), (Number(taxRatevalueobj[i].taxCalculationRate) != 0 && row.isGroup != true ? this.calcServices.calcFunction((Number(row.amount) / 100), Number(taxRatevalueobj[i].taxCalculationRate), '*') : 0), '+');
      }
      if (row.isGroup == true) {
        row.lstChild.forEach((childrow: any) => {
          if (childrow.taxRateID!!) {
            let taxRatevaluechildobj = this.lsttaxCompoundRate.filter(m => m.taxRateID == childrow.taxRateID);
            allrowTotal_withtax = Number(allrowTotal_withtax) + (Number(taxRatevaluechildobj[0]?.rate) != 0 ? Number(childrow.amount) : 0);
            //allrowTotal_tax = Number(allrowTotal_tax) + (Number(taxRatevaluechild) != 0 ? Number(childrow.taxAmount) : 0);
            for (let i = 0; i <= taxRatevaluechildobj.length - 1; i++) {
              allrowTotal_tax = this.calcServices.calcFunction(Number(allrowTotal_tax), (Number(taxRatevaluechildobj[i].taxCalculationRate) != 0 ? this.calcServices.calcFunction((Number(childrow.amount) / 100), Number(taxRatevaluechildobj[i].taxCalculationRate), '*') : 0), '+');
            }
            //allrowTotal_tax = Number(allrowTotal_tax) + (Number(taxRatevaluechild) != 0 ? (Number(childrow.amount)/100)*Number(taxRatevaluechild) : 0);
          }
        });
        if (taxTypeName == 'No Tax') {
          row.taxAmount = null;
        }
      }
    });

    if (taxTypeName != 'No Tax') {
      rowData.forEach((row: any, index) => {
        if (row.isGroup == true) {
          row.lstChild.forEach((childrow: any, childindex) => {
            if (childrow.taxRateID!!) {
              taxrateObj = this.lsttaxCompoundRate.filter(x => x.taxRateID == childrow.taxRateID);
              if (taxrateObj.length > 0) {
                let taxRate = Number(taxrateObj[0].effectiveRate) > 0 ? taxrateObj[0].effectiveRate : taxrateObj[0].taxRate;
                let a = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(childrow.amount), Number(allrowTotal_withtax), '/')), Number(taxAdjustment), '*');
                let b = Math.round((Number(a) / 1) * 100) / 100;
                let taxInc = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(childrow.amount), (100 + Number(taxRate)), '/')), Number(taxRate), '*');

                for (let i = 0; i <= taxrateObj.length - 1; i++) {
                  let ComptaxRate = taxrateObj[i].taxCalculationRate;
                  if (taxTypeName == 'Tax Exclusive') {
                    calTaxrate = this.calcServices.calcFunction(Number(childrow.amount), Number(ComptaxRate), '*') / 100;
                    if (calTaxrate != 0) {
                      let CalComptaxRate = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(b), Number(taxRate), '/')), Number(ComptaxRate), '*');
                      calTaxrate = (Math.round(Number(calTaxrate) * 100) + Math.round(Number(CalComptaxRate) * 100)) / 100;
                      adjustedtax = Math.round(adjustedtax * 100) / 100 + Math.round(CalComptaxRate * 100) / 100;
                    }

                    findTaxRate = this.calcServices.calcFunction(Number(findTaxRate), Number(calTaxrate), '+');
                    //add tax compound rate(3)
                    taxType =
                    {
                      index: index,
                      childindex: childindex,
                      taxRate: ComptaxRate,
                      taxCompoundAmt: Math.round(calTaxrate * 100) / 100,
                      taxType: "Total " + taxrateObj[i].compoundName,
                      taxID: childrow.taxRateID
                    };
                    taxCompoundList.push(taxType);
                  }
                  else if (taxTypeName == 'Tax Inclusive') {
                    calTaxrate = 0;
                    if (taxInc != 0) {
                      calTaxrate = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(taxInc), Number(taxRate), '/')), Number(ComptaxRate), '*');
                      findTaxRate = 0;
                    }
                    //add tax compound rate
                    taxType =
                    {
                      index: index,
                      childindex: childindex,
                      taxRate: ComptaxRate,
                      taxCompoundAmt: Math.round(calTaxrate * 100) / 100,
                      taxType: "Includes " + taxrateObj[i].compoundName,
                      taxID: childrow.taxRateID
                    };
                    taxCompoundList.push(taxType);
                  }
                  //total line item tax amount (sum of tax compound rate)
                  totalLineTaxAmount = this.calcServices.calcFunction(totalLineTaxAmount, calTaxrate, '+');
                }
                //add line tax amount for grid
                let linetaxamount =
                {
                  index: index,
                  childindex: childindex,
                  taxType: taxType.taxType,
                  taxcalAmt: Math.round(totalLineTaxAmount * 100) / 100,
                };
                linetaxAmountList.push(linetaxamount);
                if (totalLineTaxAmount != 0) {
                  childrow.taxAmount = Math.round(totalLineTaxAmount * 100) / 100;
                }
                else {
                  childrow.taxAmount = null;
                }

                //add line tax amount woth compound rate for total tax section(2)
                let totallinetax =
                {
                  index: index,
                  childindex: childindex,
                  taxID: childrow.taxRateID,
                  taxType: taxrateObj[0].taxName + "  " + taxrateObj[0].taxRate + "%",
                  linetaxAmount: Math.round(totalLineTaxAmount * 100) / 100,
                  taxCompoundAmountList: taxrateObj.length > 1 ? Object.assign([], taxCompoundList.filter(x => x.index == index && x.childindex == childindex)) : "",
                };
                //sum same taxid's compund rate and tax amount
                if (totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID).length > 0) {
                  totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].linetaxAmount =
                    totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].linetaxAmount +
                    totallinetax.linetaxAmount;
                  for (let k = 0; k <= totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].taxCompoundAmountList.length - 1; k++) {
                    totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].taxCompoundAmountList[k].taxCompoundAmt =
                      totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].taxCompoundAmountList[k].taxCompoundAmt +
                      totallinetax.taxCompoundAmountList[k].taxCompoundAmt;
                    if (taxTypeName == 'Tax Exclusive' || mode == "view") {
                      totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].taxCompoundAmountList[k].taxType =
                        totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].taxCompoundAmountList[k].taxType.replace('Includes', 'Total');
                    }
                  }
                }
                else {
                  if (taxTypeName == 'Tax Exclusive' || mode == "view") {
                    for (let k = 0; k <= totallinetax.taxCompoundAmountList.length - 1; k++) {
                      totallinetax.taxCompoundAmountList[k].taxType =
                        totallinetax.taxCompoundAmountList[k].taxType.replace('Includes', 'Total');
                    }
                  }
                  totallinetaxAmountsList.push(totallinetax);
                }
                totalTaxAmount = this.calcServices.calcFunction(totalTaxAmount, totalLineTaxAmount, '+');
                totalLineTaxAmount = 0;
              }
            }
          });
        }
        else {
          if (row.taxRateID!!) {
            taxrateObj = this.lsttaxCompoundRate.filter(x => x.taxRateID == row.taxRateID);
            if (taxrateObj.length > 0) {
              let taxRate = Number(taxrateObj[0].effectiveRate) > 0 ? taxrateObj[0].effectiveRate : taxrateObj[0].taxRate;
              let a = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(row.amount), Number(allrowTotal_withtax), '/')), Number(taxAdjustment), '*');
              let b = Math.round((Number(a) / 1) * 100) / 100;
              let taxInc = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(row.amount), (100 + Number(taxRate)), '/')), Number(taxRate), '*');

              for (let i = 0; i <= taxrateObj.length - 1; i++) {
                let ComptaxRate = taxrateObj[i].taxCalculationRate;

                if (taxTypeName == 'Tax Exclusive') {
                  calTaxrate = this.calcServices.calcFunction(Number(row.amount), Number(ComptaxRate), '*') / 100;
                  if (calTaxrate != 0) {
                    let CalComptaxRate = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(b), Number(taxRate), '/')), Number(ComptaxRate), '*');
                    calTaxrate = (Math.round(Number(calTaxrate) * 100) + Math.round(Number(CalComptaxRate) * 100)) / 100;
                    adjustedtax = Math.round(adjustedtax * 100) / 100 + Math.round(CalComptaxRate * 100) / 100;
                  }

                  findTaxRate = this.calcServices.calcFunction(Number(findTaxRate), Number(calTaxrate), '+');
                  //add tax compound rate(3)
                  taxType =
                  {
                    index: index,
                    taxRate: ComptaxRate,
                    taxCompoundAmt: Math.round(calTaxrate * 100) / 100,
                    taxType: "Total " + taxrateObj[i].compoundName,
                    taxID: row.taxRateID
                  };
                  taxCompoundList.push(taxType);
                }
                else if (taxTypeName == 'Tax Inclusive') {
                  calTaxrate = 0;
                  if (taxInc != 0) {
                    calTaxrate = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(taxInc), Number(taxRate), '/')), Number(ComptaxRate), '*');
                    findTaxRate = 0;
                  }
                  //add tax compound rate
                  taxType =
                  {
                    index: index,
                    taxRate: ComptaxRate,
                    taxCompoundAmt: Math.round(calTaxrate * 100) / 100,
                    taxType: "Includes " + taxrateObj[i].compoundName,
                    taxID: row.taxRateID
                  };
                  taxCompoundList.push(taxType);

                }
                //total line item tax amount (sum of tax compound rate)
                totalLineTaxAmount = this.calcServices.calcFunction(totalLineTaxAmount, calTaxrate, '+');
              }
              //add line tax amount for grid
              let linetaxamount =
              {
                index: index,
                taxType: taxType.taxType,
                taxcalAmt: Math.round(totalLineTaxAmount * 100) / 100,
              };
              linetaxAmountList.push(linetaxamount);
              if (totalLineTaxAmount != 0) {
                row.taxAmount = Math.round(totalLineTaxAmount * 100) / 100;
              }
              else {
                row.taxAmount = null;
              }
              //add line tax amount woth compound rate for total tax section(2)
              let totallinetax =
              {
                index: index,
                taxID: row.taxRateID,
                taxType: taxrateObj[0].taxName + "  " + taxrateObj[0].taxRate + "%",
                linetaxAmount: Math.round(totalLineTaxAmount * 100) / 100,
                taxCompoundAmountList: taxrateObj.length > 1 ? Object.assign([], taxCompoundList.filter(x => x.index == index)) : "",
              };
              //sum same taxid's compund rate and tax amount
              if (totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID).length > 0) {
                totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].linetaxAmount =
                  totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].linetaxAmount +
                  totallinetax.linetaxAmount;
                for (let k = 0; k <= totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].taxCompoundAmountList.length - 1; k++) {
                  totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].taxCompoundAmountList[k].taxCompoundAmt =
                    totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].taxCompoundAmountList[k].taxCompoundAmt +
                    totallinetax.taxCompoundAmountList[k].taxCompoundAmt;
                  if (taxTypeName == 'Tax Exclusive' || mode == "view") {
                    totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].taxCompoundAmountList[k].taxType =
                      totallinetaxAmountsList.filter(x => x.taxID == totallinetax.taxID)[0].taxCompoundAmountList[k].taxType.replace('Includes', 'Total');
                  }
                }
              }
              else {
                if (taxTypeName == 'Tax Exclusive' || mode == "view") {
                  for (let k = 0; k <= totallinetax.taxCompoundAmountList.length - 1; k++) {
                    totallinetax.taxCompoundAmountList[k].taxType =
                      totallinetax.taxCompoundAmountList[k].taxType.replace('Includes', 'Total');
                  }
                }
                totallinetaxAmountsList.push(totallinetax);
              }
              totalTaxAmount = this.calcServices.calcFunction(totalTaxAmount, totalLineTaxAmount, '+');
              totalLineTaxAmount = 0;
            }
          }
        }
      });
    }
    //To adjust the rounding difference in tax adjustment amount
    //let adjustedtaxdiff = Math.round(Number(taxAdjustment)*100)/100- Math.round(Number(adjustedtax)*100)/100;
    let adjustedtaxdiff = Math.round(Number(taxAdjustment) * 100 - Number(adjustedtax) * 100) / 100;
    if (adjustedtaxdiff != 0) {
      let adjsign = (adjustedtaxdiff > 0 ? -1 : 1);
      rowData.forEach((row: any, index) => {
        if (adjustedtaxdiff != 0 && adjustedtaxdiff >= -0.99 && adjustedtaxdiff <= 0.99) {
          if (row.isGroup == true) {
            row.lstChild.forEach((childrow: any, childindex) => {
              let ChildtaxRatevalue = this.lsttaxCompoundRate.filter(m => m.taxRateID == row.taxRateID).map(x => x.rate)[0];
              if (adjustedtaxdiff != 0 && adjustedtaxdiff >= -0.99 && adjustedtaxdiff <= 0.99) {
                if (childrow.taxRateID!!) {
                  if (taxTypeName == 'Tax Exclusive' && ChildtaxRatevalue > 0 && Number(childrow.taxAmount) != 0) {
                    childrow.taxAmount = childrow.taxAmount - (adjsign * .01);
                    totalTaxAmount = totalTaxAmount - (adjsign * .01);
                    findTaxRate = findTaxRate - (adjsign * .01);
                    row.taxAmount = row.taxAmount - (adjsign * .01);
                    linetaxAmountList.filter(x => x.index == index && x.childindex == childindex)[0].taxcalAmt = linetaxAmountList.filter(x => x.index == index && x.childindex == childindex)[0].taxcalAmt - (adjsign * .01);
                    totallinetaxAmountsList.filter(x => x.taxID == childrow.taxRateID)[0].linetaxAmount = totallinetaxAmountsList.filter(x => x.taxID == childrow.taxRateID)[0].linetaxAmount - (adjsign * .01);
                    adjustedtaxdiff = (Math.round(adjustedtaxdiff * 100) / 100 + (adjsign * .01));
                  }
                }
              }
            });
          }
          else {
            let taxRatevalue = this.lsttaxCompoundRate.filter(m => m.taxRateID == row.taxRateID).map(x => x.rate)[0];
            if (taxTypeName == 'Tax Exclusive' && taxRatevalue > 0 && Number(row.taxAmount) != 0) {
              row.taxAmount = row.taxAmount - (adjsign * .01);
              totalTaxAmount = totalTaxAmount - (adjsign * .01);
              findTaxRate = findTaxRate - (adjsign * .01);
              linetaxAmountList.filter(x => x.index == index)[0].taxcalAmt = linetaxAmountList.filter(x => x.index == index)[0].taxcalAmt - (adjsign * .01);
              totallinetaxAmountsList.filter(x => x.taxID == row.taxRateID)[0].linetaxAmount = totallinetaxAmountsList.filter(x => x.taxID == row.taxRateID)[0].linetaxAmount - (adjsign * .01);
              //adjustedtaxdiff = (adjustedtaxdiff*100 + (adjsign *.01)*100)/100;
              adjustedtaxdiff = (Math.round(adjustedtaxdiff * 100) / 100 + (adjsign * .01));
            }
          }
        }
      });
    }

    this.rtnObj.subTotal = Math.round(allrowTotal * 100) / 100;
    if (taxTypeName === 'Tax Exclusive') {
      this.rtnObj.total = Math.round((this.calcServices.calcFunction(Number(allrowTotal), Number(findTaxRate), '+')) * 100) / 100;
    } else if (taxTypeName === "No Tax") {
      this.rtnObj.total = this.rtnObj.subTotal
    } else {
      this.rtnObj.total = this.rtnObj.subTotal
      if (taxTypeName === 'Tax Inclusive' && mode == 'view') {
        this.rtnObj.subTotal = this.calcServices.calcFunction(this.rtnObj.subTotal, totalTaxAmount, '-');
      }
    }

    //add total tax amount(1)
    let totalTaxAmountitems =
    {
      taxType: "Total Tax Amount",
      totalTaxAmount: totalTaxAmount,
      totallinetaxAmountsList: totallinetaxAmountsList
    };
    totalTaxAmountList.push(totalTaxAmountitems);

    this.rtnObj.taxAmountsAreList = linetaxAmountList;
    this.rtnObj.LinetaxtotalAmountsAreList = totalTaxAmountList;
    return this.rtnObj;
  }



  onRowTaxCalculation(taxTypeName: string, row: any, pIndex: number, cIndex: number, field: string) {
    if (field != 'taxAmount') {
      row.taxAmount = null;
      row.orginalTaxAmount = null;
    }
    if (row.isGroup == true) {
      let taxAmount = 0.00;
      row.lstChild.forEach((childrow: any, childindex) => {
        if (cIndex == childindex || cIndex == -1) {
          if (childrow.taxRateID && taxTypeName != 'No Tax' && Number(childrow.amount) != 0) {
            let taxrateObj = this.lsttaxCompoundRate.filter(x => x.taxRateID == childrow.taxRateID);
            if (taxrateObj.length > 0) {
              let taxRate = Number(taxrateObj[0].effectiveRate) > 0 ? taxrateObj[0].effectiveRate : taxrateObj[0].taxRate;
              if (Number(taxRate) > 0) {
                if (taxTypeName == 'Tax Exclusive') {
                  if (field != 'taxAmount') {
                    childrow.taxAmount = Math.round((this.calcServices.calcFunction((Number(childrow.amount) / 100), Number(taxRate), '*')) * 100) / 100;
                  }
                }
                else if (taxTypeName == 'Tax Inclusive') {
                  childrow.taxAmount = Math.round((this.calcServices.calcFunction((this.calcServices.calcFunction(Number(childrow.amount), (100 + Number(taxRate)), '/')), Number(taxRate), '*')) * 100) / 100;
                }
              }
              else {
                childrow.taxAmount = null;
              }
            }
          }
          else {
            childrow.taxAmount = null;
          }
        }
        childrow.taxAmount = childrow.taxAmount == '-' ? '' : childrow.taxAmount;
        taxAmount = taxAmount + Number(childrow.taxAmount);
      });

      if (taxAmount) {
        row.taxAmount = Math.round(Number(taxAmount) * 100) / 100;
      }
      else {
        row.taxAmount = 0;
      }
    }
    else {
      if (row.taxRateID && taxTypeName != 'No Tax' && Number(row.amount) != 0) {
        let taxrateObj = this.lsttaxCompoundRate.filter(x => x.taxRateID == row.taxRateID);
        if (taxrateObj.length > 0) {
          let taxRate = Number(taxrateObj[0].effectiveRate) > 0 ? taxrateObj[0].effectiveRate : taxrateObj[0].taxRate;
          if (Number(taxRate) > 0) {
            if (taxTypeName == 'Tax Exclusive') {
              if (field != 'taxAmount') {
                row.taxAmount = Math.round((this.calcServices.calcFunction((Number(row.amount) / 100), Number(taxRate), '*')) * 100) / 100;
              }
            }
            else if (taxTypeName == 'Tax Inclusive') {
              row.taxAmount = Math.round((this.calcServices.calcFunction((this.calcServices.calcFunction(Number(row.amount), (100 + Number(taxRate)), '/')), Number(taxRate), '*')) * 100) / 100;
            }
          }
          else {
            row.taxAmount = null;
          }
        }
      }
      else {
        row.taxAmount = null;
      }
    }
    if (Number(row.taxAmount) > 0) {
      row.taxAmount = Math.round(Number(row.taxAmount) * 100) / 100;;
    }
  }

  onGridCalculation(taxTypeName: string, rowData: any, callFrom: string, taxAdjustment: any) {
    taxAdjustment = taxAdjustment ? taxAdjustment : 0;
    let totalWithTax = 0;
    if (callFrom == 'Adjustment') {
      rowData.forEach((row: any, index) => {
        if (row.isGroup == true) {
          row.lstChild.forEach((childrow: any, childindex) => {
            if (childrow.taxRateID) {
              let taxrateObj = this.lsttaxCompoundRate.filter(x => x.taxRateID == childrow.taxRateID);
              if (taxrateObj.length > 0) {
                let taxRate = Number(taxrateObj[0].effectiveRate) > 0 ? taxrateObj[0].effectiveRate : taxrateObj[0].taxRate;
                if (Number(taxRate) > 0) {
                  totalWithTax = this.calcServices.calcFunction(Number(totalWithTax), Number(childrow.amount), '+');
                }
              }
            }
          });
        }
        else {
          if (row.taxRateID) {
            let taxrateObj = this.lsttaxCompoundRate.filter(x => x.taxRateID == row.taxRateID);
            if (taxrateObj.length > 0) {
              let taxRate = Number(taxrateObj[0].effectiveRate) > 0 ? taxrateObj[0].effectiveRate : taxrateObj[0].taxRate;
              if (Number(taxRate) > 0) {
                totalWithTax = this.calcServices.calcFunction(Number(totalWithTax), Number(row.amount), '+');
              }
            }
          }
        }
      });
    }
    let caltaxAdjustment = 0;
    rowData.forEach((row: any, index) => {
      row.taxAmount = null;
      let orginalTaxAmount = null;
      if (row.isGroup == true) {
        let taxAmount = 0.00;
        row.lstChild.forEach((childrow: any, childindex) => {
          orginalTaxAmount = null;
          childrow.taxAmount = null;
          if (childrow.taxRateID && taxTypeName != 'No Tax') {
            let taxrateObj = this.lsttaxCompoundRate.filter(x => x.taxRateID == childrow.taxRateID);
            if (taxrateObj.length > 0) {
              let taxRate = Number(taxrateObj[0].effectiveRate) > 0 ? taxrateObj[0].effectiveRate : taxrateObj[0].taxRate;
              if (Number(taxRate) > 0) {
                if (taxTypeName == 'Tax Exclusive') {
                  orginalTaxAmount = Math.round((this.calcServices.calcFunction((Number(childrow.amount) / 100), Number(taxRate), '*')) * 100) / 100;
                  if (callFrom == 'Adjustment') {
                    let a = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(childrow.amount), Number(totalWithTax), '/')), Number(taxAdjustment), '*');
                    let b = Math.round((Number(a) / 1) * 100) / 100;
                    childrow.taxAmount = Math.round((this.calcServices.calcFunction(Number(orginalTaxAmount), Number(b), '+')) * 100) / 100;
                    caltaxAdjustment = this.calcServices.calcFunction(caltaxAdjustment, b, '+');
                  }
                  else {
                    childrow.taxAmount = Math.round(Number(orginalTaxAmount) * 100) / 100;
                  }
                }
                else if (taxTypeName == 'Tax Inclusive') {
                  orginalTaxAmount = Math.round((this.calcServices.calcFunction((this.calcServices.calcFunction(Number(childrow.amount), (100 + Number(taxRate)), '/')), Number(taxRate), '*')) * 100) / 100;
                  childrow.taxAmount = Math.round((this.calcServices.calcFunction((this.calcServices.calcFunction(Number(childrow.amount), (100 + Number(taxRate)), '/')), Number(taxRate), '*')) * 100) / 100;
                }
              }
            }
          }
          taxAmount = this.calcServices.calcFunction(taxAmount, Number(childrow.taxAmount), '+');
        });

        if (taxAmount) {
          row.taxAmount = Math.round(Number(taxAmount) * 100) / 100;
          orginalTaxAmount = Math.round(Number(taxAmount) * 100) / 100;
        }
      }
      else {
        if (row.taxRateID && taxTypeName != 'No Tax') {
          let taxrateObj = this.lsttaxCompoundRate.filter(x => x.taxRateID == row.taxRateID);
          if (taxrateObj.length > 0) {
            let taxRate = Number(taxrateObj[0].effectiveRate) > 0 ? taxrateObj[0].effectiveRate : taxrateObj[0].taxRate;
            if (Number(taxRate) > 0) {
              if (taxTypeName == 'Tax Exclusive') {
                orginalTaxAmount = Math.round(((Number(row.amount) / 100) * Number(taxRate)) * 100) / 100;
                if (callFrom == 'Adjustment') {
                  let a = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(row.amount), Number(totalWithTax), '/')), Number(taxAdjustment), '*');
                  let b = Math.round((Number(a) / 1) * 100) / 100;
                  row.taxAmount = Math.round((this.calcServices.calcFunction(Number(orginalTaxAmount), Number(b), '+')) * 100) / 100;
                  caltaxAdjustment = this.calcServices.calcFunction(caltaxAdjustment, b, '+');
                }
                else {
                  row.taxAmount = orginalTaxAmount;
                }
              }
              else if (taxTypeName == 'Tax Inclusive') {
                orginalTaxAmount = Math.round((this.calcServices.calcFunction((this.calcServices.calcFunction(Number(row.amount), (100 + Number(taxRate)), '/')), Number(taxRate), '*')) * 100) / 100;
                row.taxAmount = Math.round((this.calcServices.calcFunction((this.calcServices.calcFunction(Number(row.amount), (100 + Number(taxRate)), '/')), Number(taxRate), '*')) * 100) / 100;
              }
            }
          }
        }
        if (row.taxAmount) {
          row.taxAmount = Math.round(Number(row.taxAmount) * 100) / 100;
        }
      }
    });
    let diffAdjustment = Math.round((this.calcServices.calcFunction(Number(taxAdjustment), Number(caltaxAdjustment), '-')) * 100) / 100;
    if (diffAdjustment != 0) {
      rowData.forEach((row: any, index) => {
        let adj = 0;
        let isAdjusted = 0;
        if (diffAdjustment != 0) {
          if (diffAdjustment > 0) {
            adj = 1;
          }
          else {
            adj = -1;
          }
          if (row.isGroup == true) {
            let taxAmount = 0.00;
            let onetime = false;
            row.lstChild.forEach((childrow: any, childindex) => {
              if (Number(childrow.taxAmount) != 0 && onetime == false) {
                childrow.taxAmount = Math.round((Number(childrow.taxAmount) + (adj * .01)) * 100) / 100;
                onetime = true;
              }
              taxAmount = taxAmount + Number(childrow.taxAmount);
            });
            if (taxAmount) {
              row.taxAmount = Math.round(Number(taxAmount) * 100) / 100;
              isAdjusted = 1;
            }
          }
          else {
            if (Number(row.taxAmount) != 0) {
              row.taxAmount = Math.round((Number(row.taxAmount) + (adj * .01)) * 100) / 100;
              isAdjusted = 1;
            }
          }
          if (isAdjusted == 1)
            diffAdjustment = returnDecimal(diffAdjustment, 2) + (adj * -.01);
        }
      });
    }
  }

  onTotalCalculation(taxTypeName: string, rowData: any, PageMode?: any, IsNewViewScreen: boolean = false) {
    let subTotal = 0;
    let totalOrginalTaxAmount = 0;
    let totalTaxAmount = 0;
    let adjustmentAmount = 0;

    let taxCompoundList = [];
    let totalTaxAmountList: any = [];
    let taxType: any;
    let totallinetaxAmountsList: any = [];

    let uniqueTaxId: any = [];
    let uniqueTaxAmountsList: any = [];

    rowData.forEach((row: any, index) => {
      if (row.isGroup == true) {
        let orginalTaxAmount = null;
        row.lstChild.forEach((childrow: any, childindex) => {
          if (childrow.taxRateID && taxTypeName != 'No Tax') {
            let taxrateObj = this.lsttaxCompoundRate.filter(x => x.taxRateID == childrow.taxRateID);
            let TotalComptaxAmt = 0
            if (taxrateObj.length > 0) {
              let taxRate = Number(taxrateObj[0].effectiveRate) > 0 ? taxrateObj[0].effectiveRate : taxrateObj[0].taxRate;
              if (Number(taxRate) > 0) {
                if (taxTypeName == 'Tax Exclusive') {
                  childrow.orginalTaxAmount = Math.round((this.calcServices.calcFunction((Number(childrow.amount) / 100), Number(taxRate), '*')) * 100) / 100;
                }
                else if (taxTypeName == 'Tax Inclusive') {
                  childrow.orginalTaxAmount = Math.round((this.calcServices.calcFunction((this.calcServices.calcFunction(Number(childrow.amount), (100 + Number(taxRate)), '/')), Number(taxRate), '*')) * 100) / 100;
                }

                taxCompoundList = [];
                TotalComptaxAmt = 0
                for (let i = 0; i <= taxrateObj.length - 1; i++) {
                  let ComptaxRate = taxrateObj[i].taxCalculationRate;
                  let ComptaxAmt = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(childrow.taxAmount), Number(taxRate), '/')), Number(ComptaxRate), '*');

                  let taxType =
                  {
                    index: index,
                    taxRate: ComptaxRate,
                    taxCompoundAmt: Math.round(ComptaxAmt * 100) / 100,
                    fullTaxCompoundAmt: ComptaxAmt,
                    taxType: taxTypeName == 'Tax Exclusive' ? "Total " + taxrateObj[i].compoundName : "Includes " + taxrateObj[i].compoundName,
                    taxID: childrow.taxRateID
                  };
                  taxCompoundList.push(taxType);
                  TotalComptaxAmt = this.calcServices.calcFunction(TotalComptaxAmt, ComptaxAmt, '+');
                }

                let totallinetax =
                {
                  index: index,
                  taxID: childrow.taxRateID,
                  taxType: taxrateObj[0].taxName + "  " + taxrateObj[0].taxRate + "%",
                  linetaxAmount: Math.round(TotalComptaxAmt * 100) / 100,
                  fullLinetaxAmount: TotalComptaxAmt,
                  taxCompoundAmountList: taxrateObj.length > 1 ? Object.assign([], taxCompoundList.filter(x => x.index == index)) : "",
                };

                if (!uniqueTaxId.includes(childrow.taxRateID)) {
                  uniqueTaxId.push(childrow.taxRateID);
                }
                totallinetaxAmountsList.push(totallinetax);
              }
              else {
                childrow.orginalTaxAmount = null;
              }
            }
            else {
              childrow.orginalTaxAmount = null;
            }
          }
          else {
            childrow.orginalTaxAmount = null;
          }

          orginalTaxAmount = this.calcServices.calcFunction(Number(orginalTaxAmount), Number(childrow.orginalTaxAmount), '+');
        });
        if (Number(orginalTaxAmount) != 0) {
          row.orginalTaxAmount = orginalTaxAmount;
        }
      }
      else {
        if (row.taxRateID && taxTypeName != 'No Tax') {
          let taxrateObj = this.lsttaxCompoundRate.filter(x => x.taxRateID == row.taxRateID);
          let TotalComptaxAmt = 0
          if (taxrateObj.length > 0) {
            let taxRate = Number(taxrateObj[0].effectiveRate) > 0 ? taxrateObj[0].effectiveRate : taxrateObj[0].taxRate;
            if (Number(taxRate) > 0) {
              if (taxTypeName == 'Tax Exclusive') {
                row.orginalTaxAmount = Math.round((this.calcServices.calcFunction((Number(row.amount) / 100), Number(taxRate), '*')) * 100) / 100;
              }
              else if (taxTypeName == 'Tax Inclusive') {
                row.orginalTaxAmount = Math.round((this.calcServices.calcFunction((this.calcServices.calcFunction(Number(row.amount), (100 + Number(taxRate)), '/')), Number(taxRate), '*')) * 100) / 100;
              }
              taxCompoundList = [];
              TotalComptaxAmt = 0
              for (let i = 0; i <= taxrateObj.length - 1; i++) {
                let ComptaxRate = taxrateObj[i].taxCalculationRate;
                let ComptaxAmt = this.calcServices.calcFunction((this.calcServices.calcFunction(Number(row.taxAmount), Number(taxRate), '/')), Number(ComptaxRate), '*');

                taxType =
                {
                  index: index,
                  taxRate: ComptaxRate,
                  taxCompoundAmt: Math.round(ComptaxAmt * 100) / 100,
                  fullTaxCompoundAmt: ComptaxAmt,
                  taxType: taxTypeName == 'Tax Exclusive' ? "Total " + taxrateObj[i].compoundName : "Includes " + taxrateObj[i].compoundName,
                  taxID: row.taxRateID
                };
                taxCompoundList.push(taxType);
                TotalComptaxAmt = this.calcServices.calcFunction(TotalComptaxAmt, ComptaxAmt, '+');
              }

              let totallinetax =
              {
                index: index,
                taxID: row.taxRateID,
                taxType: taxrateObj[0].taxName + "  " + taxRate + "%", // taxrateObj[0].taxRate
                linetaxAmount: Math.round(TotalComptaxAmt * 100) / 100,
                fullLinetaxAmount: TotalComptaxAmt,
                taxCompoundAmountList: taxrateObj.length > 1 ? Object.assign([], taxCompoundList.filter(x => x.index == index)) : "",
              };

              if (!uniqueTaxId.includes(row.taxRateID)) {
                uniqueTaxId.push(row.taxRateID);
              }
              totallinetaxAmountsList.push(totallinetax);
            }
            else {
              row.orginalTaxAmount = null;
            }
          }
          else {
            row.orginalTaxAmount = null;
          }
        }
        else {
          row.orginalTaxAmount = null;
        }
      }
    });



    for (let t = 0; t < uniqueTaxId.length; t++) {
      let sametax = totallinetaxAmountsList.filter(x => x.taxID == uniqueTaxId[t]);
      let obj = {
        index: sametax[0].index,
        linetaxAmount: sametax[0].linetaxAmount,
        fullLinetaxAmount: sametax[0].fullLinetaxAmount,
        taxCompoundAmountList: sametax[0].taxCompoundAmountList,
        taxID: sametax[0].taxID,
        taxType: sametax[0].taxType,
      }
      uniqueTaxAmountsList.push(obj);

      if (sametax.length > 1) {
        for (let s = 1; s < sametax.length; s++) {

          uniqueTaxAmountsList.filter(x => x.taxID == uniqueTaxId[t])[0].fullLinetaxAmount = (uniqueTaxAmountsList.filter(x => x.taxID == uniqueTaxId[t])[0].fullLinetaxAmount + sametax[s].fullLinetaxAmount);

          uniqueTaxAmountsList.filter(x => x.taxID == uniqueTaxId[t])[0].linetaxAmount = Math.round((uniqueTaxAmountsList.filter(x => x.taxID == uniqueTaxId[t])[0].fullLinetaxAmount) * 100) / 100;

          for (let k = 0; k <= uniqueTaxAmountsList.filter(x => x.taxID == uniqueTaxId[t])[0].taxCompoundAmountList.length - 1; k++) {

            uniqueTaxAmountsList.filter(x => x.taxID == uniqueTaxId[t])[0].taxCompoundAmountList[k].fullTaxCompoundAmt =
              (uniqueTaxAmountsList.filter(x => x.taxID == uniqueTaxId[t])[0].taxCompoundAmountList[k].fullTaxCompoundAmt + sametax[s].taxCompoundAmountList[k].fullTaxCompoundAmt);

            uniqueTaxAmountsList.filter(x => x.taxID == uniqueTaxId[t])[0].taxCompoundAmountList[k].taxCompoundAmt =
              Math.round((uniqueTaxAmountsList.filter(x => x.taxID == uniqueTaxId[t])[0].taxCompoundAmountList[k].fullTaxCompoundAmt) * 100) / 100;
          }
        }
      }
    }


    rowData.forEach((row: any, index) => {
      subTotal = row.amount ? (subTotal + Number((Math.round(row.amount * 100) / 100))) : (subTotal + 0);
      totalOrginalTaxAmount = row.orginalTaxAmount ? (totalOrginalTaxAmount + Number(row.orginalTaxAmount)) : (totalOrginalTaxAmount + 0);
      row.taxAmount = row.taxAmount == '-' ? '' : row.taxAmount;
      totalTaxAmount = row.taxAmount ? (totalTaxAmount + Number((row.taxAmount))) : (totalTaxAmount + 0);

      if (row.taxAmount) {
        row.taxAmount = Math.round(Number(row.taxAmount) * 100) / 100;
      }
    });
    let a = (Math.round(totalTaxAmount * 100) / 100) - (Math.round(totalOrginalTaxAmount * 100) / 100);
    if (a != 0) {
      adjustmentAmount = Math.round(a * 100) / 100;
    }

    this.rtnObj.totalTax = 0;
    if (Number(totalTaxAmount)) {
      this.rtnObj.totalTax = Math.round((totalTaxAmount) * 100) / 100;
    }
    this.rtnObj.adjustmentAmount = null;
    if (taxTypeName === 'Tax Exclusive') {
      this.rtnObj.subTotal = Math.round(subTotal * 100) / 100;
      this.rtnObj.adjustmentAmount = Math.round(adjustmentAmount * 100) / 100;
      this.rtnObj.total = Math.round((this.calcServices.calcFunction(Number(subTotal), Number(totalTaxAmount), '+')) * 100) / 100;
    }
    else if (taxTypeName === "No Tax" || taxTypeName === 'Tax Inclusive') {
      this.rtnObj.subTotal = Math.round(subTotal * 100) / 100;
      this.rtnObj.total = this.rtnObj.subTotal
      if ((PageMode == 'view' && !IsNewViewScreen) || PageMode == ''
        && taxTypeName === 'Tax Inclusive') {  //&& PageMode == 'view'
        this.rtnObj.subTotal = Math.round((this.calcServices.calcFunction(subTotal, totalTaxAmount, '-')) * 100) / 100;
      }
    }

    let totalTaxAmountitems =
    {
      taxType: "Total Tax Amount",
      totalTaxAmount: totalTaxAmount,
      totallinetaxAmountsList: uniqueTaxAmountsList
    };
    totalTaxAmountList.push(totalTaxAmountitems);
    this.rtnObj.LinetaxtotalAmountsAreList = totalTaxAmountList;

    return this.rtnObj;
  }

  jounalManualAdjustment(transactionType: string, findTaxRate: number, rowData: any, adjustmentDiff: number, newtaxList, taxTypeName) {
    if (adjustmentDiff != 0) {
      let adjSign = adjustmentDiff > 0 ? -1 : 1;
      rowData.forEach((row: any, index) => {
        if (adjustmentDiff != 0 && adjustmentDiff >= -0.99 && adjustmentDiff <= 0.99) {
          let taxRateValue = this.lsttaxCompoundRate.filter(tax => tax.taxRateID == row.taxRateID).map(tax => tax.rate)[0];
          if (taxTypeName == 'Tax Exclusive' && taxRateValue > 0 && Number(row[transactionType]) != 0) {
            row[transactionType] = row[transactionType] - (adjSign * .01);
            findTaxRate = findTaxRate - (adjSign * .01);
            row.taxAmount = returnDecimal(row?.taxAmount - (adjSign * .01), 2);
            adjustmentDiff = (Math.round(adjustmentDiff * 100) / 100 + (adjSign * .01));
            newtaxList.filter(tax => tax.taxRateID == row.taxRateID)[0][transactionType] = newtaxList.filter(tax => tax.taxRateID == row.taxRateID)[0][transactionType] - (adjSign * .01);
          }
        }
      })
    }
    return findTaxRate;
  }
}

