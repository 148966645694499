import { AllCommunityModules, ColDef, ColumnApi, GridApi, GridOptions, Module } from '@ag-grid-community/all-modules';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AgdatepickerComponent } from '../gridComponent/agdatepicker.component';
import { AlphanumericEditor } from '../gridComponent/alphanumeric-editor.component';
import { QuanityUOMComponent } from '../gridComponent/quanity-uom.component';
import { HttpServices } from '../../../utilities/_services/http.service';
import { DatePipe } from '@angular/common';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';
import { SeriallistComponent } from '../seriallist/seriallist.component';
import { MasterServices } from '../../../utilities/_services/master.service';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';
import { AdvanceinventoryService } from '../../../utilities/_services/advanceinventory.service';
import { WarehouseComponent } from '../../inventory/warehouse/warehouse.component';
import { WarehouselistComponent } from '../warehouselist/warehouselist.component';

@Component({
  selector: 'app-assignserial',
  templateUrl: './assignserial.component.html',
  styleUrls: ['./assignserial.component.scss']
})
export class AssignserialComponent implements OnInit {

  @ViewChild('modalAssignSerial')
  public modalAssignSerial: ModalDirective;
  trackingMethod: string = 'Serial Number';
  serialItemDetails: any = [];
  serialItemsGridOptions: GridOptions;
  modules: Module[] = AllCommunityModules;
  itemscolumnDefs: ColDef[] = [];
  serialMasterData: any = [];
  _requiredQuantity: number = 0;
  tracktype:string='';
  _POQuantity: number = 0;
  frameworkComponents = {
    datePicker: AgdatepickerComponent,
    quantity: QuanityUOMComponent,
    serialList: SeriallistComponent,
    warehouseList: WarehouselistComponent
  }
  itemID: number = 0;
  _mode: string = 'View';
  _transactionTypeId: number = 0;
  _transactionDetailId: number = 0;
  DODetailId: number;
  transactionQuantity: number;
  _quantityLabel: string = 'Invoice Order';
  _transactionType: any = [
    { transactionTypeId: 6, transactionTypeName: 'Invoice Order' },
    { transactionTypeId: 21, transactionTypeName: 'Deliver Order' },
    { transactionTypeId: 4, transactionTypeName: 'Purchase Credit Note' },
    { transactionTypeId: 8, transactionTypeName: 'Sales Credit Note' },
    { transactionTypeId: 30, transactionTypeName: 'Sales Debit Note' },
  ];
  lstSerial: any;
  paginationPageSize: any;
  lstPagination: any = [];
  totalGridRows: number = 0;
  // selectedRowCount: number = 0;
  assignedQty: number = 0;
  unAssignedQty: number = 0;
  searchText: string;
  @Output() emitOnInvoiceSumbit = new EventEmitter<any>();
  @Output() emitOnDOSerialSumbit = new EventEmitter<any>();
  @Output() emitonSubmit = new EventEmitter<any>();
  @Output() emitAfterBatchorSerialSaved = new EventEmitter<any>();  
  isWarehouseEnabled: boolean = false;
  orgSettings: any;
  lstWarehouse: any;
  gridApi:GridApi;
  gridColumnApi: ColumnApi;
  agGridHearderColumnDefTemplateString: string = '<div class="ag-cell-label-container" role="presentation">' +
    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
    '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
    '   &nbsp <span class="text-danger">*</span>' +
    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
    '  </div>' +
    '</div>';
    isEnabledActionButtons: boolean = true;
    @Output() emitOnDebitNoteSumbit = new EventEmitter<any>();
  constructor(private http: HttpServices,
    private datepipe: DatePipe,
    private master: MasterServices,
    private alertMessage: AlertMessage,
    private local: LocalCacheServices,
    private AI: AdvanceinventoryService
  ) {
    this.paginationPageSize = this.master.paginationPageSize;
    this.lstPagination = this.master.getPagination();
    this.orgSettings = JSON.parse(this.local.getlocalStorage(this.local.localCurrentOrganization));
    this.isWarehouseEnabled = this.orgSettings.enableWarehouse;
  }

  ngOnInit(): void {
    this.initGrid();
  }

  ngAfterViewInit() {

  }

  initGrid() {
    this.serialItemsGridOptions = <GridOptions>{
      domLayout: "autoHeight",
      sortable: false,
      filter: false,
      wrapText: true,
      autoHeight: true,
      rowHeight: 40,
      stopEditingWhenCellsLoseFocus: true,
      onGridReady: (params: any) => {
        this.serialItemsGridOptions.api.sizeColumnsToFit();
        this.gridApi = params.api;
        this.gridColumnApi = params.api.columnApi;
      },
      onGridSizeChanged: () => {
        this.serialItemsGridOptions.api.sizeColumnsToFit();
      },
    }
  }

  dateFormatter(params) {
    return params.value == "" ? params.value : (moment(params.value)).format('DD/MM/YYYY');
  }

  onQuickFilterChanged() {
    this.serialItemsGridOptions.api.setQuickFilter(this.searchText);
    this.totalGridRows = this.serialItemsGridOptions.api.getDisplayedRowCount();
    if (this.totalGridRows == 0) {
      this.serialItemsGridOptions.api.showNoRowsOverlay();
    }
    else {
      this.serialItemsGridOptions.api.hideOverlay();
    }
  }

  onCellClicked(params: any) {
    const colId = params.column.getId();
    if (colId === "Delete" && !params.data.isLocked) {
      this.onSortChanged();   
      const selectedRow = this.serialItemsGridOptions.api.getFocusedCell();
      this.serialMasterData.splice(selectedRow.rowIndex, 1);
      this.calculateAssingedQuantity();
      this.serialItemsGridOptions.api.setRowData(this.serialMasterData);
    } else if (colId == 'serialID') {
      // if (this.isWarehouseEnabled) {
      //   let warehouseID = params.data.warehouseID;
      //   if (warehouseID == 0 || warehouseID == null || warehouseID == undefined) {
      //     this.setNameCellEditorValues([]);
      //   } else {
      //     let serialNoList = this.lstSerial.filter(ele => ele.warehouseID == warehouseID);
      //     this.setNameCellEditorValues(serialNoList);
      //   }
      // }
    }
    this.setDrowdownValues_Warehouses_Serials();
  }

  onDeleteInvoiceSerialItemDetails() {
    this.http.deleteservice(environment.salesApiUrl + `/SalesInvoice/DeleteInvoiceSerialItems?ItemId=${this.itemID}&InvoiceTransDetailId=${this._transactionDetailId}`).subscribe({
      next: (data: any) => {
        if (data.isSuccess === true) {
          this.alertMessage.successNotifier("Saved Successfully", 0);
          this.emitOnInvoiceSumbit.emit({trans:'Invoice',track:this.tracktype}); 
          this.closeModal();
        }
        else {
          this.alertMessage.errorNotifier(data.ResponseData, 0);
        }
      }
    });
  }

  onDeleteVCNSerialItemDetails() {
    if(this.assignedQty==0){
      this.tracktype=' - Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype=' - Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype=' - Partial Tracked';
    }
    this.http.deleteservice(environment.purchaseApiUrl + `/Creditnotes/onDeleteVCNSerial?ItemID=${this.itemID}&CNTransID=${this._transactionDetailId}`).subscribe({
      next: (response: any) => {
        if (response.isSuccess === true) {
          this.alertMessage.successNotifier("Saved Successfully", 0);
          this.emitAfterBatchorSerialSaved.emit({
            transactionTypeID: 4,
            transactionTypeName: 'VCN',
            transactionDetailID: this._transactionDetailId,
            track:this.tracktype
          });
          this.closeModal();
        } else {
          this.alertMessage.errorNotifier(response.ResponseData, 0);
        }
      }
    });
  }

  onDeleteSCNSerialItemDetails() {
    if(this.assignedQty==0){
      this.tracktype=' - Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype=' - Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype=' - Partial Tracked';
    }
    this.http.deleteservice(environment.salesApiUrl + `/SalesCreditnotes/DeleteSerialItems?ItemID=${this.itemID}&SCNTransID=${this._transactionDetailId}`).subscribe({
      next: (data: any) => {
        if (data.isSuccess === true) {
          this.alertMessage.successNotifier("Saved Successfully", 0);
          this.emitAfterBatchorSerialSaved.emit({
            transactionTypeID: 8,
            transactionTypeName: 'SCN',
            transactionDetailID: this._transactionDetailId,
            track:this.tracktype
          });
          this.closeModal();
        } else {
          this.alertMessage.errorNotifier(data.ResponseData, 0);
        }
      }
    });
  }

  onDeleteDOSerialItemDetails() {
    this.http.deleteservice(environment.salesApiUrl + `/Delivery/DeleteDOSerialItems?ItemID=${this.itemID}&DeliveryDetailId=${this._transactionDetailId}`).subscribe({
      next: (data: any) => {
        if (data.isSuccess === true) {
          this.alertMessage.successNotifier("Saved Successfully", 0);
          this.emitOnInvoiceSumbit.emit({trans:'DO',track:this.tracktype});
          this.closeModal();
        } else {
          this.alertMessage.errorNotifier(data.ResponseData, 0);
        }
      }
    });
  }

  closeModal() {
    this.modalAssignSerial.hide();
  }


  async onSubmit() {
    if (this._transactionTypeId == 6) {//Invoice
      if (this.onUIValidate()) {
        this.closeModal();
        this.onSaveUpdateInvoiceSerialDetails();
      }
    } else if (this._transactionTypeId == 21) {//DO
      if (this.onUIValidate()) {
        this.closeModal();
        this.onSaveUpdateDeliverySerialDetails();
      }
    } else if (this._transactionTypeId == 4) {//VCN
      if (this.onUIValidate()) {
        this.closeModal();
        this.onSaveUpdateVCNSerialDetails();
      }
    } else if (this._transactionTypeId == 8) {//SCN
      if (this.onUIValidate()) {
        this.onSaveUpdateSCNSerialDetails();
      }
    }if (this._transactionTypeId == 30) {//Sales Debit Note
      if (this.onUIValidate()) {
        this.closeModal();
        this.onSaveUpdateDebitNoteSerialDetails();
      }
    } 
  }

  addRows(count): void {
    for (let i = 1; i <= count; i++) {
      let rowFeild = {
        serialID: 0,
        serialNo: '',
        manufacturedDate: '',
        expiryDate: '',
        quantity: '',
        availableQty: '',
        isTemporary: 1,
        transactionTypeId:this._transactionTypeId
        // itemId: this.itemID
      }
      this.serialMasterData.push(rowFeild);
      this.serialItemsGridOptions.api.addItems([rowFeild]);
    }
  }

  async openModal(_itemID: number, _transactionQuantity: number, _transactionDetailId: number, _transactionTypeId: number, _mode: string,_isPriceAdjustment?:boolean) {
    await this.createColumnDefs(_transactionTypeId);   
    this.serialItemsGridOptions.columnApi.applyColumnState({ defaultState: { sort: null } });
    this._transactionTypeId = _transactionTypeId;
    this._transactionDetailId = _transactionDetailId;
    this._quantityLabel = this._transactionType.find(x => x.transactionTypeId == _transactionTypeId).transactionTypeName;
    if (!isNaN(_transactionQuantity)) {
      this._requiredQuantity = _transactionQuantity;
    }
    if (_transactionTypeId == 6) { //Serial from Invoice Order
      await this.setWarehouseAndSerialList(_itemID);
      await this.getAssignedSerialByInvoiceDetailId(_transactionDetailId, _itemID);
      this.modalAssignSerial.show();
    } else if (_transactionTypeId == 21) { //Serial from Delivery Order
      await this.setWarehouseAndSerialList(_itemID);
      await this.getAssignedSerialByDODetailId(_transactionDetailId, _itemID);
      this.modalAssignSerial.show();
    } else if (_transactionTypeId == 4) { //Serial from VCN
      await this.setWarehouseAndSerialList(_itemID);
      await this.getAssignedSerialByCNTransId(_transactionDetailId, _itemID);
      this.modalAssignSerial.show();
    } else if (_transactionTypeId == 8) { //Serial from SCN
      await this.setWarehouseAndSerialList(_itemID);
      await this.getAssignedSerialBySCNDetailId(_transactionDetailId, _itemID);
      this.modalAssignSerial.show();
    }
    if (_transactionTypeId == 30) { //Serial from Sales Debit Note
      if(_isPriceAdjustment == true){
        this.isEnabledActionButtons = false;
      }
      else{
        this.isEnabledActionButtons = true;
      }
      await this.setWarehouseAndSerialList(_itemID);
      await this.getAssignedSerialByDebitNoteDetailId(_transactionDetailId, _itemID);
      this.modalAssignSerial.show();
    } 
  }

  private async setWarehouseAndSerialList(_itemID: number) {
    this.lstSerial = await this.getSerialNumberList(_itemID, this._transactionDetailId, this._transactionTypeId);
    let response: any = await this.AI.getWarehouseList();
    this.lstWarehouse = response.responseData;
    this.setDrowdownValues_Warehouses_Serials();
  }

  private setDrowdownValues_Warehouses_Serials() {
    this.setNameCellEditorValues_Warehouse();
    this.setNameCellEditorValues_Serial();
  }

  async getWarehouseDetails(): Promise<any> {
    let returnvalue: any[];
    let data: any;
    data = await this.http.getserviceawait(environment.purchaseApiUrl + `/Bills/GetWareHouseDetails`);
    const Tn = data.responseData
    if (data.isSuccess && data.responseData != null) {
      returnvalue = data.responseData.map(row => ({
        warehouseID: row.warehouseID,
        warehouseName: row.warehouseName
      }));
    }
    return returnvalue;
  }

  async getAssignedSerialBySCNDetailId(SCNTransId: number, itemID: number) {
    this.itemID = itemID;
    let data: any = await this.http.getserviceawait(environment.salesApiUrl + `/SalesCreditnotes/GetAssignedSerial?SCNTransId=${SCNTransId}&ItemId=${itemID}`);
    if (data.isSuccess && data.responseData != null) {
      this.serialItemDetails = [];
      this.serialMasterData = [];
      this.serialItemDetails = data.responseData;
      if (this.serialItemDetails['assignedQuantity']!)
        this.assignedQty = this.serialItemDetails['assignedQuantity'];
      if (this.serialItemDetails['unassignedQuantity']!)
        this.unAssignedQty = this.serialItemDetails['unassignedQuantity'];
      this.serialMasterData = this.serialItemDetails.serialDetails.map(ele => ({
        serialID: ele.serialID,
        serialNo: ele.serialNo,
        manufacturedDate: ele.manufacturedDate ?? "",
        expiryDate: ele.expiryDate ?? "",
        quantity: ele.quantity,
        transactionTypeId: ele.transactionTypeId,
        // isLocked: ele.isLocked,
        isLocked: false,
        warehouseID: ele.warehouseID,
        invoiceDetailId: ele.invoiceDetailId,
        isCreatedFromCN: ele.isCreatedFromCN
      }));
      this.serialItemsGridOptions.api.addItems(this.serialMasterData);
    }
  }

  async getAssignedSerialByInvoiceDetailId(_invoiceDetailId: number, _itemID: number) {
    this.itemID = _itemID;
    let data: any = await this.http.getserviceawait(environment.salesApiUrl + `/SalesInvoice/GetAssignedSerial?InvoiceDetailId=${_invoiceDetailId}&ItemId=${_itemID}`);
    if (data.isSuccess && data.responseData != null) {
      this.serialMasterData = [];
      this.serialItemDetails = data.responseData;
      // if (this.serialItemDetails['assignedQuantity']!)
      this.assignedQty = this.serialItemDetails['assignedQuantity'];
      // if (this.serialItemDetails['unassignedQuantity']!)
      this.unAssignedQty = this.serialItemDetails['unassignedQuantity'];
      if (data.responseData.serialDetails.length > 0) {
        data.responseData.serialDetails.forEach(ele => {
          let rowFeild = {
            serialID: ele.serialID,
            serialNo: ele.serialNo,
            manufacturedDate: ele.manufacturedDate == null ? "" : ele.manufacturedDate,
            expiryDate: ele.expiryDate == null ? "" : ele.expiryDate,
            quantity: ele.quantity,
            availableQty: ele.availableQty,
            isTemporary: 0,
            warehouseID: ele.warehouseID
          }
          this.serialMasterData.push(rowFeild);
          this.serialItemsGridOptions.api.addItems([rowFeild]);
        });
      }
    }
  }

  async getAssignedSerialByDODetailId(_DODetailId: number, _itemID: number) {
    this.itemID = _itemID;
    this.DODetailId = _DODetailId;
    // this.transactionQuantity = _transactionQuantity;
    let data: any = await this.http.getserviceawait(environment.salesApiUrl + `/Delivery/GetAssignedSerial?DeliveryDetailId=${_DODetailId}&ItemId=${_itemID}`);
    if (data.isSuccess && data.responseData != null) {
      this.serialMasterData = [];
      this.serialItemDetails = data.responseData;
      // if (this.serialItemDetails['assignedQuantity']!)
      this.assignedQty = this.serialItemDetails['assignedQuantity'];
      // if (this.serialItemDetails['unassignedQuantity']!)
      this.unAssignedQty = this.serialItemDetails['unassignedQuantity'];
      if (data.responseData.serialDetails.length > 0) {
        data.responseData.serialDetails.forEach(ele => {
          let rowFeild = {
            serialID: ele.serialID,
            serialNo: ele.serialNo,
            manufacturedDate: ele.manufacturedDate == null ? "" : ele.manufacturedDate,
            expiryDate: ele.expiryDate == null ? "" : ele.expiryDate,
            quantity: ele.quantity,
            availableQty: ele.availableQty,
            isTemporary: 0,
            warehouseID: ele.warehouseID
          }
          this.serialMasterData.push(rowFeild);
          this.serialItemsGridOptions.api.addItems([rowFeild]);
        });
      }
    }
  }


  onUIValidate(): boolean {
    const errorMessage = [];
    const totalQuantity = this.serialMasterData.map((ele) => Number(ele.quantity)).reduce((a, b) => a + b, 0);
    const validRows = this.serialMasterData.filter(e => e.warehouseID > 0 || e.serialID > 0 || Number(e.quantity) > 0).length;
    this.serialMasterData.filter(e => e.warehouseID > 0 || e.serialID > 0 || Number(e.quantity) > 0).forEach((ele, index) => {
      index++;
      if (Number(ele.quantity) == 0) {
        errorMessage.push({ message: 'Row Number ' + (index) + ' : Quantity should not be blank or zero' });
      } if (this.serialMasterData.filter(column => column.serialID == ele.serialID).length > 1) {
        errorMessage.push({ message: 'Row Number ' + (index) + ' : Serial No. cannot be duplicate' });
      }
    });
    if (totalQuantity > this._requiredQuantity) {
      errorMessage.push({ message: 'Serial quantity should not be exceed than required quantity' });
    }
    // if (validRows == 0) {
    //   errorMessage.push({ message: 'There are no rows to save, Please add Serial No(s)' });
    // } 
    if (errorMessage.length > 0) {
      this.alertMessage.errorSummaryNotifierWithAutoClose(errorMessage, 0);
      return false;
    }
    return true;
  }

  async getSerialNumberList(_itemID: number, _transDetailId, _transTypeId: number): Promise<any> {
    let returnValue: any[];
    let data: any = await this.http.getserviceawait(environment.inventoryApiUrl + `/Inventory/GetSerialNumberList?ItemId=${_itemID}&transDetailId=${_transDetailId}&transTypeId=${_transTypeId}`);
    if (data.isSuccess && data.responseData != null) {
      returnValue = data.responseData.map(row => ({
        serialID: row.serialID,
        serialNo: row.serialNo,
        availableQty: row.quantity,
        manufacturedDate: row.manufacturedDate,
        expiryDate: row.expiryDate,
        warehouseID: row.warehouseID
      }));
    }
    return returnValue;
  }

  getSelectableSerials() {
    let selectedSerials: { SerialID: number, WarehouseID: number }[] = [];
    this.serialMasterData.forEach(e => {
      // if ((e.serialID ?? false) && (e.warheouseID ?? false))
      selectedSerials.push({ SerialID: e.serialID, WarehouseID: e.warehouseID })
    });
    let serials = [];
    this.lstSerial.forEach(e => {
      if (selectedSerials.findIndex(ss => ss.SerialID == e.serialID && ss.WarehouseID == e.warehouseID) == -1) {
        serials.push(e);
      }
    });
    return serials;
  }

  setNameCellEditorValues_Serial() {
    let colDefs: any = this.serialItemsGridOptions.api.getColumnDefs();
    let serials = this.getSelectableSerials();
    colDefs[1].cellEditorParams.lstSerial = serials;
    colDefs[1].cellEditorParams.allSerials = this.lstSerial;
    this.serialItemsGridOptions.api.setColumnDefs(colDefs);
  }

  setNameCellEditorValues_Warehouse() {
    let warehouses = [];
    let colDefs: any = this.serialItemsGridOptions.api.getColumnDefs();
    if ([4, 8].some(e => e == this._transactionTypeId))
      warehouses = this.lstWarehouse;
    else
      warehouses = this.getWarehousesTobeListed();
    colDefs[0].cellEditorParams.lstWarehouse = warehouses;
    this.serialItemsGridOptions.api.setColumnDefs(colDefs);
  }

  getWarehousesTobeListed() {
    let warehouses = [];
    this.lstWarehouse.forEach(e => {
      let isWarehouseHasBatch = this.getSelectableSerials().find(lb => lb.warehouseID == e.warehouseID);
      if (isWarehouseHasBatch) warehouses.push(e);
    });
    return warehouses;
  }


  lookupValue(serialNumberList: any, params: any) {
    if (params.value == null || params.value == "" || params.value == undefined || serialNumberList == null) {
      return;
    }
    else {
      let index = 0;
      if (params.column.colId == 'serialID') {
        index = serialNumberList.findIndex(item => item.serialID == params.value);
        if (index !== -1) {
          // if (params.data.isLocked == true)
          //   this.disableSerialNoControl();
          return serialNumberList[index].serialNo;
        } else {
          return "";
        }
      }
    }
  }

  lookupAvailableQty(serialNumberList: any, params: any) {
    return '1';
  }

  async onSaveUpdateInvoiceSerialDetails(): Promise<boolean> {
    let isSuccess = false;
    const serialDetailsModel = [];
    if(this.assignedQty==0){
      this.tracktype='- Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype='- Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype='- Partial Tracked';
    }
    if (this.serialMasterData.length > 0) {
      for (var i = 0; i < this.serialMasterData.filter(e => e.serialID > 0 || Number(e.quantity) > 0).length; i++) {//Ignoring empty rows
        serialDetailsModel.push({
          "SerialID": this.serialMasterData[i].serialID,
          "ItemID": this.itemID,
          "Quantity": this.serialMasterData[i].quantity,
          "InvoiceDetailId": this._transactionDetailId,
          "WarehouseID": this.serialMasterData[i].warehouseID 
        });
      }
      let APIResponse = await this.http.awaitPostservice(environment.salesApiUrl + '/SalesInvoice/SaveUpdateInvoiceSerialDetails', serialDetailsModel);
      if (APIResponse["isSuccess"] == false) {
        let responseMessage = APIResponse["responseData"];
        responseMessage = JSON.parse(responseMessage);
        this.alertMessage.errorSummaryNotifierWithAutoClose(responseMessage, 0);
        isSuccess = false;
      } else {
        isSuccess = true;
        this.alertMessage.successNotifier('Save Successfully', 0);
        this.emitOnInvoiceSumbit.emit({trans:'Invoice',track:this.tracktype});
      }
    } else {
      this.onDeleteInvoiceSerialItemDetails();
    }
    return isSuccess
  }

  async onSaveUpdateDeliverySerialDetails(): Promise<boolean> {
    let isSuccess = false;
    const serialDetailModel = [];
    if(this.assignedQty==0){
      this.tracktype=' - Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype=' - Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype=' - Partial Tracked';
    }
    if (this.serialMasterData.length > 0) {
      for (var i = 0; i < this.serialMasterData.length; i++) {
        serialDetailModel.push({
          "SerialID": this.serialMasterData[i].serialID,
          "ItemID": this.itemID,
          "Quantity": this.serialMasterData[i].quantity,
          "DeliveryDetailID": this._transactionDetailId,
          "WarehouseID": this.serialMasterData[i].warehouseID
        });
      }
      let APIResponse = await this.http.awaitPostservice(environment.salesApiUrl + '/Delivery/SaveUpdateDOSerialDetails', serialDetailModel);
      if (APIResponse["isSuccess"] == false) {
        let responseData = APIResponse["responseData"];
        let errorMessage = [];
        if (responseData.message.length > 0) {
          responseData.message.forEach(element => {
            errorMessage.push({ message: element });
          });
        }
        this.alertMessage.errorNotifierList(errorMessage, 0);
        isSuccess = false;
      } else {
        isSuccess = true;
        this.alertMessage.successNotifier("Saved Successfully", 0);
        this.emitOnInvoiceSumbit.emit({trans:'DO',track:this.tracktype});
      }
    }
    else {
      this.onDeleteDOSerialItemDetails();
    }
    return isSuccess
  }

  async onSaveUpdateVCNSerialDetails(): Promise<boolean> {
    let isSuccess = false;
    if(this.assignedQty==0){
      this.tracktype=' - Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype=' - Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype=' - Partial Tracked';
    }
    const serialDetailModel = [];
    if (this.serialMasterData.length > 0) {
      for (var i = 0; i < this.serialMasterData.length; i++) {
        serialDetailModel.push({
          "SerialID": this.serialMasterData[i].serialID,
          "SerialNo": this.lstSerial.find(e => e.serialID == this.serialMasterData[i].serialID).serialNo,
          "ItemID": this.itemID,
          "Quantity": this.serialMasterData[i].quantity,
          "CNTransID": this._transactionDetailId,
          "BillDetailID": this.serialMasterData[i].billDetailId,
          "WarehouseID": this.serialMasterData[i].warehouseID
        });
      }
      let APIResponse = await this.http.awaitPostservice(environment.purchaseApiUrl + '/Creditnotes/SaveUpdateSerialDetails', serialDetailModel);
      if (APIResponse["isSuccess"] == false) {
        let responseData = APIResponse["responseData"];
        let errorMessage = [];
        if (responseData.message.length > 0) {
          responseData.message.forEach(element => {
            errorMessage.push({ message: element });
          });
        }
        this.alertMessage.errorNotifierList(errorMessage, 0);
        isSuccess = false;
      } else {
        isSuccess = true;
        this.alertMessage.successNotifier("Saved Successfully", 0);
        this.emitAfterBatchorSerialSaved.emit({
          transactionTypeID: 4,
          transactionTypeName: 'VCN',
          transactionDetailID: this._transactionDetailId,
          track:this.tracktype
        });
      }
    } else {
      this.onDeleteVCNSerialItemDetails();
    }
    return isSuccess
  }

  async onSaveUpdateSCNSerialDetails(): Promise<boolean> {
    let isSuccess = false;
    const serialDetailModel = [];
    if(this.assignedQty==0){
      this.tracktype=' - Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype=' - Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype=' - Partial Tracked';
    }
    if (this.serialMasterData.length > 0) {
      for (var i = 0; i < this.serialMasterData.length; i++) {
        serialDetailModel.push({
          "SerialID": this.serialMasterData[i].serialID,
          "SerialNo": this.lstSerial.find(e => e.serialID == this.serialMasterData[i].serialID).serialNo,
          "ItemID": this.itemID,
          "Quantity": this.serialMasterData[i].quantity,
          "SCNTransId": this._transactionDetailId,
          "InvoiceDetailId": this.serialMasterData[i].invoiceDetailId,
          "WarehouseID": this.serialMasterData[i].warehouseID,
          "ManufacturedDate": this.serialMasterData[i].manufacturedDate != '' ? this.serialMasterData[i].manufacturedDate : null,
          "ExpiryDate": this.serialMasterData[i].expiryDate != '' ? this.serialMasterData[i].expiryDate : null,
          "IsCreatedFromCN": this.serialMasterData[i].isCreatedFromCN
        });
      }
      let APIResponse = await this.http.awaitPostservice(environment.salesApiUrl + '/SalesCreditnotes/SaveUpdateSerialDetails', serialDetailModel);
      if (APIResponse["isSuccess"] == false) {
        let responseData = APIResponse["statusMessage"];
        responseData = JSON.parse(responseData);
        let errorList = responseData.map(ele => ({ message: ele.Message })) ?? [];
        this.alertMessage.errorNotifierList(errorList, 0);
        isSuccess = false;
      } else {
        isSuccess = true;
        this.closeModal();
        this.alertMessage.successNotifier("Saved Successfully", 0);
        this.emitAfterBatchorSerialSaved.emit({
          transactionTypeID: 8,
          transactionTypeName: 'SCN',
          transactionDetailID: this._transactionDetailId,
          track:this.tracktype
        });
      }
    } else {
      this.onDeleteSCNSerialItemDetails();
    }
    return isSuccess
  }

  private generateUniqueID(): number {
    while (true) {
      let newSerialId = Math.floor(Math.random() * 1000 + 1);
      let i = this.lstSerial.findIndex(e => e.serialID == (newSerialId));
      if (i == -1)
        return newSerialId;
    }
  }

  addSerial(serialInfo: any) {
    this.lstSerial.push({
      serialID: serialInfo.serialID,
      serialNo: serialInfo.serialNo,
      availableQty: 1,
      manufacturedDate: null,
      expiryDate: null,
      warehouseID: serialInfo.warehouseID
    });
  }

  async onCellValueChanged(params: any) {
    const colId = params.column.getId();
    if (colId === "serialID") {
      if (params?.value?.isNew == true) {
        let isAlreadyExists = this.lstSerial?.filter(ele => ele.serialNo == params.value.serialNo).length > 0 ? true : false;
        if (isAlreadyExists == false) {
          const serialInfo = {
            serialID: this.generateUniqueID(),
            serialNo: params.value.serialNo,
            warehouseID: params.data.warehouseID
          };
          this.addSerial(serialInfo);
          this.serialMasterData[params.rowIndex].serialID = serialInfo.serialID;
          this.serialMasterData[params.rowIndex].quantity = 1;
          this.serialMasterData[params.rowIndex].isCreatedFromCN = true;
        } else {
          this.alertMessage.errorNotifier(params.value.serialNo + ' is already exists', 0);
        }
      } else {
        let serialInfo = this.lstSerial.filter(item => item.serialID == params.data.serialID);
        if (serialInfo?.length == 1) {
          this.serialMasterData[params.rowIndex].quantity = serialInfo[0].availableQty;
          this.serialMasterData[params.rowIndex].manufacturedDate = serialInfo[0].manufacturedDate == null ? '' : serialInfo[0].manufacturedDate;
          this.serialMasterData[params.rowIndex].expiryDate = serialInfo[0].expiryDate == null ? '' : serialInfo[0].expiryDate;
        } else {
          this.serialMasterData[params.rowIndex].quantity = '';
          this.serialMasterData[params.rowIndex].manufacturedDate = '';
          this.serialMasterData[params.rowIndex].expiryDate = '';
        }
      }
      params.api.refreshCells({
        force: true,
      });
    } else if (colId === "warehouseID") {
      // let serialNo = this.getSelectableSerials().filter(item => item.warehouseID == params.data.warehouseID);
      // if (serialNo?.length > 0) {
      //   // let availableQty = await this.onFetchAvailableSerialQuantity(this.itemID, this._transactionDetailId, params.data.serialID, params.data.warehouseID, this._transactionTypeId);
      //   this.serialMasterData[params.rowIndex].serialID = serialNo[0].serialID;
      //   this.serialMasterData[params.rowIndex].quantity = serialNo[0].availableQty;
      //   this.serialMasterData[params.rowIndex].manufacturedDate = serialNo[0].manufacturedDate == null ? '' : serialNo[0].manufacturedDate;
      //   this.serialMasterData[params.rowIndex].expiryDate = serialNo[0].expiryDate == null ? '' : serialNo[0].expiryDate;
      // } else {
      //   this.serialMasterData[params.rowIndex].serialID = 0;
      //   this.serialMasterData[params.rowIndex].quantity = '';
      //   this.serialMasterData[params.rowIndex].manufacturedDate = '';
      //   this.serialMasterData[params.rowIndex].expiryDate = '';
      // }
      // params.api.refreshCells({
      //   force: true,
      // });
      this.serialMasterData[params.rowIndex].serialID = '';
      this.serialMasterData[params.rowIndex].quantity = '';
      this.serialMasterData[params.rowIndex].manufacturedDate = '';
      this.serialMasterData[params.rowIndex].expiryDate = '';
      params.api.refreshCells({
        force: true,
      });
    }
      this.calculateAssingedQuantity();
      this.setDrowdownValues_Warehouses_Serials();
  }

  onPageSizeChanged() {
    this.serialItemsGridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    this.serialItemsGridOptions.api.sizeColumnsToFit();
  }

  calculateAssingedQuantity() {
    this.assignedQty = this.serialMasterData.filter(ele => ele.serialID > 0).length;
  }

  lookupValue_Warehouse(warehouseList: any, params: any) {
    if (params.value == null || params.value == "" || params.value == undefined || warehouseList == null) {
      return;
    }
    else {
      let index = 0;
      if (params.column.colId == 'warehouseID') {
        index = warehouseList.findIndex(item => item.warehouseID == params.value);
        if (index != -1) {
          return warehouseList[index].warehouseName;
        } else {
          return "";
        }
      }
    }
  }

  async createColumnDefs(transactionTypeID: number) {
    this.itemscolumnDefs = [
      {
        headerName: "Warehouse",
        field: "warehouseID",
        sortable: true,
        filter: true,
        wrapText: true,
        editable: true,
        cellEditor: 'warehouseList',
        hide: !this.isWarehouseEnabled,
        cellEditorParams: {
          lstWarehouse: [],
          isWarehouseEnabled: this.isWarehouseEnabled
        },
        cellStyle: {
          'overflow-y': 'auto!important',
          'overflow': 'visible'
        },
        cellRenderer: (params: any) => {
          return this.lookupValue_Warehouse(this.lstWarehouse, params);
        },
        headerComponentParams: {
          template: this.agGridHearderColumnDefTemplateString
        }
      },
      {
        headerName: "Serial No.",
        field: "serialID",
        wrapText: true,
        editable: (params: any) => {
          return ([4, 8,30].some(e => e == transactionTypeID) && params.data.isLocked) ? false : true
        },
        autoHeight: true,
        sortable: true,
        filter: true,
        cellEditor: 'serialList',
        cellEditorParams: {
          lstSerial: [],
          allSerials: [],
          isWarehouseEnabled: this.isWarehouseEnabled,
          isShowCreateNew: [4, 8].some(e => e == transactionTypeID) ? true : false,
        },
        cellStyle: {
          'overflow': 'visible'
        },
        cellRenderer: (params: any) => {
          return this.lookupValue(this.lstSerial, params);
        },
        headerComponentParams: {
          template: this.agGridHearderColumnDefTemplateString
        }
      },
      {
        headerName: "Manufactured Date",
        field: "manufacturedDate",
        unSortIcon: true,
        // editable: false,
        sortable: true,
        filter: true,
        cellEditor: 'datePicker',
        valueFormatter: this.dateFormatter,
        editable: (params) => {
          return (params?.data?.isCreatedFromCN as boolean)
        }
      },
      {
        headerName: "Expiry Date",
        field: "expiryDate",
        wrapText: true,
        sortable: true,
        filter: true,
        // editable: false,
        cellEditor: 'datePicker',
        autoHeight: true,
        valueFormatter: this.dateFormatter,
        editable: (params) => {
          return (params?.data?.isCreatedFromCN as boolean)
        }
      },
      {
        headerName: "Quantity",
        field: "quantity",
        wrapText: true,
        editable: false,
        sortable: true,
        filter: true,
        autoHeight: true,
        headerComponentParams: {
          template: this.agGridHearderColumnDefTemplateString
        }
        // cellRenderer: (params: any) => {
        //   return this.lookupAvailableQty(this.lstSerial, params);
        // }
      },
      {
        headerName: "",
        field: "Delete",
        editable: false,
        cellStyle: { textAlign: 'center' },
        cellRenderer: (params) => {
          // return '<span class="close-x" data-action="delete">x</span>';
          if (params.node.data.isLocked) {
            return `<div  tabindex="-1" unselectable="on" role="gridcell" aria-colindex="1"  class="ag-cell ag-cell-not-inline-editing ag-cell-auto-height" style="left: 0px;bottom:0px; pointer-events: none; opacity: 0.4;">
            <span class="c-icon material-icons-outlined m-0" style="font-size:20px">lock</span>
          </div>`;
          } else {
            return '<span class="close-x" data-action="delete">x</span>';
          }
        },
        width: 50,
        maxWidth: 50,
      }
    ];
  }

  async getAssignedSerialByCNTransId(VCNTransId: number, itemID: number) {
    this.itemID = itemID;
    let data: any = await this.http.getserviceawait(environment.purchaseApiUrl + `/Creditnotes/GetAssignedSerial?VCNTransId=${VCNTransId}&ItemId=${itemID}`);
    if (data.isSuccess && data.responseData != null) {
      this.serialItemDetails = [];
      this.serialItemDetails = [];
      this.serialItemDetails = data.responseData;
      if (this.serialItemDetails['assignedQuantity']!)
        this.assignedQty = this.serialItemDetails['assignedQuantity'];
      if (this.serialItemDetails['unassignedQuantity']!)
        this.unAssignedQty = this.serialItemDetails['unassignedQuantity'];
      this.serialMasterData = this.serialItemDetails.serialDetails.map(ele => ({
        serialID: ele.serialID,
        serialNo: ele.serialNo,
        manufacturedDate: ele.manufacturedDate ?? "",
        expiryDate: ele.expiryDate ?? "",
        quantity: ele.quantity,
        transactionTypeId: ele.transactionTypeId,
        isLocked: ele.isLocked,
        warehouseID: ele.warehouseID,
        billDetailId: ele.billDetailID,
        isCreatedFromCN: ele.isCreatedFromCN
      }));
      this.serialItemsGridOptions.api.addItems([this.serialMasterData]);
    }
  }

  disableSerialNoControl() {
    let colDefs: ColDef = this.serialItemsGridOptions.api.getColumnDef('serialID');
    colDefs.cellEditorParams.editable = false;
    this.serialItemsGridOptions.api.setColumnDefs([colDefs]);
  }

  async onFetchAvailableSerialQuantity(_itemID: number, _transDetailId: number, _serialID: number, _warehouseID: number, _transactionTypeId: number): Promise<number> {
    let availableSerialQty: number;
    let data: any = await this.http.getserviceawait(environment.inventoryApiUrl + `/Inventory/GetAvailableBatchQty?ItemId=${_itemID}&transDetailId=${_transDetailId}&serialID=${_serialID}&warehouseID=${_warehouseID}&transactionTypeId=${_transactionTypeId}`);
    if (data.isSuccess && data.responseData != null) {
      return availableSerialQty = data.responseData;
    }
  }

  async onSortChanged() {
    const isSortApplied = this.gridApi.getSortModel().length > 0;
    const rowElements = [];
    this.gridApi.forEachNode(ele => rowElements.push({ rowIndex: ele.rowIndex, data: ele.data }));
    rowElements.sort((a, b) => {
      return a.rowIndex - b.rowIndex
    });
    if (isSortApplied) {
      if (this._transactionTypeId == 4) {
        this.serialMasterData = rowElements.map(col => col.data).map(ele => ({
          serialID: ele.serialID,
          serialNo: ele.serialNo,
          manufacturedDate: ele.manufacturedDate ?? "",
          expiryDate: ele.expiryDate ?? "",
          quantity: ele.quantity,
          transactionTypeId: ele.transactionTypeId,
          isLocked: ele.isLocked,
          warehouseID: ele.warehouseID,
          billDetailId: ele.billDetailID,
          isCreatedFromCN: ele.isCreatedFromCN
        })) ?? [];
      } else if (this._transactionTypeId == 8) {
        this.serialMasterData = rowElements.map(col => col.data).map(ele => ({
          serialID: ele.serialID,
          serialNo: ele.serialNo,
          manufacturedDate: ele.manufacturedDate ?? "",
          expiryDate: ele.expiryDate ?? "",
          quantity: ele.quantity,
          transactionTypeId: ele.transactionTypeId,
          isLocked: ele.isLocked,
          warehouseID: ele.warehouseID,
          invoiceDetailId: ele.invoiceDetailId,
          isCreatedFromCN: ele.isCreatedFromCN
        })) ?? [];
      } else {
        this.serialMasterData = rowElements.map(col => col.data).map(ele => ({
          serialID: ele.serialID,
          serialNo: ele.serialNo,
          manufacturedDate: ele.manufacturedDate == null ? "" : ele.manufacturedDate,
          expiryDate: ele.expiryDate == null ? "" : ele.expiryDate,
          quantity: ele.quantity,
          availableQty: ele.availableQty,
          isTemporary: 0,
          warehouseID: ele.warehouseID
        })) ?? [];
      }
    }
  }

  async getAssignedSerialByDebitNoteDetailId(_debitNoteDetailId: number, _itemID: number) {
    this.itemID = _itemID;
    let data: any = await this.http.getserviceawait(environment.salesApiUrl + `/DebitNote/GetAssignedSerial?DebitNoteDetailId=${_debitNoteDetailId}&ItemId=${_itemID}`);
    if (data.isSuccess && data.responseData != null) {
      this.serialMasterData = [];
      this.serialItemDetails = data.responseData;
      this.assignedQty = this.serialItemDetails['assignedQuantity'];
      this.unAssignedQty = this.serialItemDetails['unassignedQuantity'];
      if (data.responseData.serialDetails.length > 0) {
        data.responseData.serialDetails.forEach(ele => {
          let rowFeild = {
            serialID: ele.serialID,
            serialNo: ele.serialNo,
            manufacturedDate: ele.manufacturedDate == null ? "" : ele.manufacturedDate,
            expiryDate: ele.expiryDate == null ? "" : ele.expiryDate,
            quantity: ele.quantity,
            availableQty: ele.availableQty,
            isTemporary: 0,
            warehouseID: ele.warehouseID,
            isLocked :ele.isLocked,
            transactionTypeId:ele.transactionTypeId
          }
          this.serialMasterData.push(rowFeild);
          this.serialItemsGridOptions.api.addItems([rowFeild]);
        });
      }
    }
  }
  async onSaveUpdateDebitNoteSerialDetails(): Promise<boolean> {
    let isSuccess = false;
    const serialDetailsModel = [];
    if(this.assignedQty==0){
      this.tracktype='- Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype='- Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype='- Partial Tracked';
    }
    if (this.serialMasterData.filter(a=>a.transactionTypeId==30).length > 0) {
      for (var i = 0; i < this.serialMasterData.filter(e => e.serialID > 0 || Number(e.quantity) > 0).length; i++) {//Ignoring empty rows
        serialDetailsModel.push({
          "SerialID": this.serialMasterData[i].serialID,
          "ItemID": this.itemID,
          "Quantity": this.serialMasterData[i].quantity,
          "DebitNoteDetailId": this._transactionDetailId,
          "WarehouseID": this.serialMasterData[i].warehouseID,
          "IsLocked": this.serialMasterData[i].isLocked,
        });
      }
      let APIResponse = await this.http.awaitPostservice(environment.salesApiUrl + '/DebitNote/SaveUpdateDebitNoteSerialDetails', serialDetailsModel);
      if (APIResponse["isSuccess"] == false) {
        let responseMessage = APIResponse["responseData"];
        responseMessage = JSON.parse(responseMessage);
        this.alertMessage.errorSummaryNotifierWithAutoClose(responseMessage, 0);
        isSuccess = false;
      } else {
        isSuccess = true;
        this.alertMessage.successNotifier('Save Successfully', 0);
        this.emitOnDebitNoteSumbit.emit({trans:'Sales Debit Note',track:this.tracktype,transactionDetailID:this._transactionDetailId});
      }
    } else {
      this.onDeleteDebitNoteSerialItemDetails();
    }
    return isSuccess
  }

  onDeleteDebitNoteSerialItemDetails() {
    this.http.deleteservice(environment.salesApiUrl + `/DebitNote/DeleteDebitNoteSerialItems?ItemId=${this.itemID}&DebitNoteDetailId=${this._transactionDetailId}`).subscribe({
      next: (data: any) => {
        if (data.isSuccess === true) {
          this.alertMessage.successNotifier("Saved Successfully", 0);
          this.emitOnDebitNoteSumbit.emit({trans:'Sales Debit Note',track:this.tracktype,transactionDetailID:this._transactionDetailId});
          
          this.closeModal();
        }
        else {
          this.alertMessage.errorNotifier(data.ResponseData, 0);
        }
      }
    });
  }
}
