import { Component, OnInit, Inject, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MasterServices } from '../../../utilities/_services/master.service';
import { FormBuilder, FormGroup, FormArray, Validators, NgForm } from '@angular/forms';
import { CustomValidator, CustomTxtValidator, NumbersRegEx, ValidAccountCode } from '../../../utilities/_directives/customvalidator.directive';
import { environment } from '../../../../environments/environment'
import { HttpServices } from '../../../utilities/_services/http.service';
import { PageServices } from '../../../utilities/_services/page.service';
import { AlertMessage } from '../../../utilities/_helpers/alert.message'
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';
import { NZTaxrateListServices } from '../../../utilities/_services/nztaxratelist.service copy';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-addaccount',
  templateUrl: './addaccount.component.html',
  styleUrls: ['./addaccount.component.scss']
})
export class AddaccountComponent implements OnInit {
  addAccountForm: FormGroup | any;
  loading = false;
  submitted = false;
  action: string;
  params: any;
  allrowData: any
  systemCode: string = '';
  accTypeID: number;

  get ShowPaymentAccount(): boolean {
    let accounts = [
      'Realised Foreign Exchange Gains or Losses',
      'Unrealised Foreign Exchange Gains or Losses',
      'Accounts Receivable',
      'Accounts Payable',
      'GST - INPUT',
      'GST - OUTPUT',
      'GST Expense',
      'Retained Earnings'
    ];

    if (accounts.includes(this.addAccountForm.value.accountName)) {
      return false;
    }
    if (this.addAccountForm.value.accountTypeID == 3) {
      return false;
    }

    return true;
  }

  @ViewChild('showAddAccountModal') public showAddAccountModal: ModalDirective;
  @Output() sentToParent = new EventEmitter<String>();
  oldValue: any;
  accCodeExists: any = {};
  lstAllTaxRateMaster: any = [];
  lstTaxRateMaster: any = [];
  lstAccountsType: any = [];
  accountName: boolean = false;
  curentUser: any;
  systemName: string = '';
  islocked: boolean = false;
  lstAllCAOMaster: any = [];
  lstCAOTypeMaster: any = [];
  orgSettings: any;
  errorNotifierID = 0;
  constructor(private master: MasterServices, private formBuilder: FormBuilder, private http: HttpServices,
    private page: PageServices, private alertMessage: AlertMessage, private local: LocalCacheServices, private nztaxrate: NZTaxrateListServices, private translate: TranslateService) {
    // this.action = data.action;
    // this.params = data.params;
    // this.allrowData=data.allrowData;
    this.curentUser = JSON.parse(
      this.local.getlocalStorage(this.local.localCurrentUserKey)
    );

    this.orgSettings = JSON.parse(this.local.getlocalStorage(this.local.localCurrentOrganization));
  }

  async getData(action, params, allrowData) {
    this.action = action;
    this.params = params;
    this.allrowData = allrowData;
    this.lstAllCAOMaster = await this.master.GetParentAccounts();
    this.loadAccount();
    this.showAddAccountModal.show()
  }

  async GetTaxRateMaster() {
    this.lstAllTaxRateMaster = await this.master.GetTaxRateMaster();
    this.lstAllCAOMaster = await this.master.GetParentAccounts();
    this.lstTaxRateMaster = this.lstAllTaxRateMaster;
  }
  async GetAccountsType() {

    this.lstAccountsType = await this.master.GetAccountsType();
    if (this.lstAccountsType.length > 0) {
      this.lstAccountsType = this.lstAccountsType.filter(x => x.accountName != "Bank")
    }
  }

  showSubAccountCtrl = false;
  onAccountsType() {// If any

    this.loadParentAccounts()

    if (this.addAccountForm.value.caAccountID > 0) {
      Swal.fire({
        title: this.translate.instant('Common.Confirm'),
        text: this.translate.instant('SharedModule.Changing account type may affect your accounting and reports. Do you wish to proceed?'),
        //footer: 'Note: Deleting Accounts is permanent and cannot be undone. Archived Accounts can be Restored at any time.',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: this.translate.instant('Common.Yes'),
        cancelButtonText: this.translate.instant('Common.No')
      }).then((result) => {
        if (result.value) {
          if (this.curentUser.orgCountryID === 153) {//Only for NZ Country
            let pt = this.lstAccountsType.filter(x => x.accTypeID == this.addAccountForm.value.accountTypeID);
            if (pt.length > 0) {
              this.lstTaxRateMaster = this.nztaxrate.onNZTaxRateList(pt[0].accountGroupID);
            }

            let t = this.lstTaxRateMaster.filter(x => x.mstrTaxRateID == this.addAccountForm.value.taxID);
            if (t.length == 0) {
              this.addAccountForm.patchValue({
                taxID: null
              });
            }
          }


        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.addAccountForm.patchValue({
            accountTypeID: JSON.parse(this.oldValue).accountTypeID
          });
          return false;
        }
      })
    }
    else {
      if (this.curentUser.orgCountryID === 153) {//Only for NZ Country
        let pt = this.lstAccountsType.filter(x => x.accTypeID == this.addAccountForm.value.accountTypeID);
        if (pt.length > 0) {
          this.lstTaxRateMaster = this.nztaxrate.onNZTaxRateList(pt[0].accountGroupID);
        }

        let t = this.lstTaxRateMaster.filter(x => x.mstrTaxRateID == this.addAccountForm.value.taxID);
        if (t.length == 0) {
          this.addAccountForm.patchValue({
            taxID: null
          });
        }
      }
    }
  }

  loadAccount() {
    if (this.action == 'A') {
      this.islocked = false;
      this.addAccountForm.patchValue({
        caAccountID: 0,
        accountCode: '',
        accountName: '',
        accDescription: '',
        accountTypeID: null,
        taxID: null,
        showOnWatchList: false,
        isLocked: false,
        isPaymentAccount: false,
        ParentCAAccountID: [null],
        isSubAccount: [false]

      });
      this.lstCAOTypeMaster = [];
      this.showSubAccountCtrl = false;
      //this.systemCode = '';
      this.addAccountForm.controls.accountTypeID.enable();
      this.oldValue = null;
      this.submitted = false;
      this.accountName = false;
      this.accCodeExists = { code: '', name: '', isExists: false, isShow: false };
    }
    else if (this.action == 'E') {
      this.addAccountForm.patchValue({
        caAccountID: this.params.data.caAccountID,
        accountCode: this.params.data.accountCode,
        accountName: this.params.data.accountName,
        accDescription: this.params.data.accDescription,
        accountTypeID: this.params.data.accountTypeID,
        taxID: this.params.data.taxID,
        showOnWatchList: this.params.data.showOnWatchList,
        isLocked: this.params.data.isLocked,
        isPaymentAccount: this.params.data.isPaymentAccount,
        parentCAAccountID: this.params.data.parentCAAccountID,
        isSubAccount: this.params.data.isSubAccount,
      });

      this.loadParentAccounts('E')
      this.systemCode = this.params.data.systemCode;
      if (this.params.data.systemCode != '') {
        this.islocked = true;
        this.systemName = this.params.data.systemName;
        this.accTypeID = this.addAccountForm.controls.accountTypeID.value;
        this.addAccountForm.controls.accountTypeID.disable();
      }
      else {
        this.islocked = false;
        this.addAccountForm.controls.accountTypeID.enable();
      }

      if (['Retained Earnings', 'Unrealised Currency Gains'].includes(this.params.data.systemName))
        this.addAccountForm.controls.showOnWatchList.disable();
      else
        this.addAccountForm.controls.showOnWatchList.enable();

      this.oldValue = JSON.stringify(this.addAccountForm.value);
      this.submitted = false;
      this.accountName = false;
      this.accCodeExists = { code: '', name: '', isExists: false, isShow: false };
    }
    else if (this.action == 'ADE') {
      this.islocked = false;
      this.addAccountForm.patchValue({
        caAccountID: this.params[0].caAccountID,
        accountCode: this.params[0].accountCode,
        accountName: this.params[0].accountName,
        accDescription: this.params[0].accDescription,
        accountTypeID: this.params[0].accountTypeID,
        taxID: this.params[0].taxID,
        showOnWatchList: this.params[0].showOnWatchList,
        isLocked: this.params[0].isLocked,
        isPaymentAccount: this.params[0].isPaymentAccount,
        parentCAAccountID: this.params[0].parentCAAccountID,
        isSubAccount: this.params[0].isSubAccount,
        includeUnrealisedAutoCalculation:
          this.params[0].includeUnrealisedAutoCalculation,
      });
      this.loadParentAccounts('E');
      // this.accounttypeChanges();
      this.systemCode = this.params[0].systemCode;
      if (this.params[0].systemCode != '') {
        this.accTypeID = this.addAccountForm.controls.accountTypeID.value;
        this.addAccountForm.controls.accountTypeID.disable();
      }
      else
        this.addAccountForm.controls.accountTypeID.enable();

      if ([960, 498].includes(this.params[0].systemAccountCode))
        this.addAccountForm.controls.showOnWatchList.disable();
      else
        this.addAccountForm.controls.showOnWatchList.enable();

      this.oldValue = JSON.stringify(this.addAccountForm.value);
      this.submitted = false;
      this.accCodeExists = { code: '', name: '', isExists: false, isShow: false };
    }
  }

  ngOnInit(): void {
    this.GetTaxRateMaster();
    this.GetAccountsType();

    this.addAccountForm = this.formBuilder.group({
      caAccountID: [0],
      accountCode: ['', CustomTxtValidator.required], //, Validators.pattern(NumbersRegEx.pattern)
      accountName: ['', CustomTxtValidator.required],
      accDescription: [''],
      accountTypeID: [null, CustomValidator.required],
      taxID: [null, CustomValidator.required],
      showOnWatchList: [false],
      isLocked: [false],
      isPaymentAccount: [false],
      parentCAAccountID: [null],
      isSubAccount: [false]
    });

  }
  get f() { return this.addAccountForm.controls; }

  saveAddAccount() {
    this.submitted = true;
    if (this.addAccountForm.invalid) {
      return;
    }
    if (this.accCodeExists.isExists == true) {
      return;
    }
    if (this.accountName) {
      return;
    }
    if (this.addAccountForm.value.caAccountID > 0) {
      if (this.action == 'ADE') {
        if (this.params[0].isParentAccount && this.params[0].accountTypeID != this.addAccountForm.value.accountTypeID) {
          this.errorNotifierID = this.alertMessage.errorNotifier("Account Type cannot change for Parent Accounts", this.errorNotifierID);
          return;
        }
      }
      else {
        if (this.params.data.isParentAccount && this.params.data.accountTypeID != this.addAccountForm.value.accountTypeID) {
          this.errorNotifierID = this.alertMessage.errorNotifier("Account Type cannot change for Parent Accounts", this.errorNotifierID);
          return;
        }
      }
    }

    this.submitted = false;
    this.addAccountForm.patchValue({
      accountName: this.addAccountForm.value.accountName.trim(),
      accDescription: (this.addAccountForm.value.accDescription) ? this.addAccountForm.value.accDescription.trim() : null
    });
    if (this.systemCode != '') {
      this.addAccountForm.controls.accountTypeID.enable();
      this.addAccountForm.patchValue({
        accountTypeID: this.accTypeID,
      });
    }
    this.loading = true;
    let payload = { oldValue: this.oldValue, newValue: JSON.stringify(this.addAccountForm.value), pageID: this.page.pageDetails.pageID };
    this.http.postservice(environment.accountsApiUrl + '/ChartofAccounts/SaveUpdateChartOfAccount/', payload).subscribe({
      next: (data) => {
        let retundata: any = data;
        if (retundata.responseData == "1" || retundata.responseData == "2") {
          this.sentToParent.emit('save');
          this.showAddAccountModal.hide();
        }
        else {
          let SucessBody = {
            title: "",
            message: retundata.responseData,
            footer: " "
          }
          this.alertMessage.alertWithCustomizeWarning(SucessBody);
        }
        this.loading = false;
      }
    });
  }

  accountCodeExists() {
    // if (!this.addAccountForm.value.accountCode.toString().match(/^[A-Za-z0-9\/.\-_]*?$/g)) {
    //   return false;
    // }
    if (this.addAccountForm.value.accountCode != '') {
      let accCode = this.allrowData.filter(item => item.accountCode.toLowerCase() === this.addAccountForm.value.accountCode.toLowerCase());

      if (accCode.length > 0) {
        if (this.addAccountForm.value.caAccountID != accCode[0].caAccountID) {
          this.accCodeExists = { code: this.addAccountForm.value.accountCode, name: accCode[0].accountName, isExists: true, isShow: true };
        }
        else {
          this.accCodeExists = { code: '', name: '', isExists: false, isShow: false };
        }
      }
      else {
        this.accCodeExists = { code: this.addAccountForm.value.accountCode, name: '', isExists: false, isShow: true };
      }
    }
    else {
      this.accCodeExists = { code: '', name: '', isExists: false, isShow: false };
    }
  }

  accountNameExists() {
    let accName = this.allrowData.filter(item => item.accountName.toLowerCase() === this.addAccountForm.value.accountName.toLowerCase());
    if (accName?.length > 0) {
      if (!!this.params) {
        if (this.params?.data['accountName'].toLowerCase() !== accName[0]?.accountName.toLowerCase()) {
          this.accountName = true;
        } else {
          this.accountName = false;
        }
      } else {
        this.accountName = true;
      }

    } else {
      this.accountName = false;
    }
  }
  cancel() {
    this.accountName = false;
  }

  changeIsSubAccount(e, type?: string) {
    if (e.checked) {
      this.addAccountForm.controls.parentCAAccountID.enable();
      this.addAccountForm.controls.parentCAAccountID.setValidators([Validators.required]);
      this.addAccountForm.controls.parentCAAccountID.updateValueAndValidity();
    } else {
      this.addAccountForm.patchValue({ parentCAAccountID: null });
      this.addAccountForm.controls.parentCAAccountID.setValidators(null);
      this.addAccountForm.controls.parentCAAccountID.updateValueAndValidity();
      this.addAccountForm.controls.parentCAAccountID.disable();
    }
  }

  loadParentAccounts(type?: string) {
    let accType = this.lstAccountsType.find(r => r.accTypeID == this.addAccountForm.controls.accountTypeID.value)
    if (!!accType) {
      if (accType.allowSubGroups) {
        this.showSubAccountCtrl = true;
        this.lstAllCAOMaster.forEach(R => {
          if (R.SubLevel >= (this.orgSettings.maxSubAccountLevel - 1))
            R.disabled = true
        })

        this.lstCAOTypeMaster = this.lstAllCAOMaster.filter(r => r.AccountTypeID == this.addAccountForm.controls.accountTypeID.value
          && this.addAccountForm.value.caAccountID != r.CAAccountID);
      } else {
        this.addAccountForm.patchValue({ isSubAccount: false, parentCAAccountID: null });
        this.showSubAccountCtrl = false;
        this.lstCAOTypeMaster = [];

      }
    } else {
      this.showSubAccountCtrl = false;
    }
    if (type != 'E') {
      this.addAccountForm.patchValue({ isSubAccount: false, parentCAAccountID: null });
    }

    this.changeIsSubAccount({ checked: this.addAccountForm.controls.isSubAccount.value }, type);
  }
}
