import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class CalculationService {

  constructor() { }

   getCalcTotal(params: any) {
      let qtyMrp =  this.calcFunction(Number(params.quanity), Number(params.unitprice), '*');
   return qtyMrp
  }
   calcDiscount(params: any,qtyMrp:any) {
    let disc = params.discount;
    let discRate = Number(disc) / 100 * Number(qtyMrp);
    return discRate;
  }

  taxamountCalculation(rowData:any,taxrateObj:any) 
  {
    let calTaxrate:any = 0;
    for (let i = 0; i <= taxrateObj.length - 1; i++) {
      let txr = taxrateObj[i].rate;
      let Taxrate = this.calcFunction((this.calcFunction(Number(rowData.calamount), (100 + Number(txr)), '/')), Number(txr), '*');
      calTaxrate += Taxrate;
      }  
    return  Math.round(calTaxrate * 100) / 100;
  }

  calcFunction(val1, val2, operators): any {
    val1 = (val1 ?? 0);
    val2 = (val2 ?? 0);
    let arrVal1 = val1.toString().split('.');
    let arrVal2 = val2.toString().split('.');
    let roundOff = 0;
    if(arrVal1.length > 0 && arrVal2.length > 0) {
        roundOff = (arrVal1.length > 1 ? arrVal1[1].length : 0) + (arrVal2.length > 1 ? arrVal2[1].length : 0);
        roundOff = (roundOff > 12 ? 12 : roundOff);
    }
    return (
                operators == '+' ? Math.round((Number(val1) + Number(val2)) * (Math.pow(10, roundOff))) / (Math.pow(10, roundOff)) :
                operators == '-' ? Math.round((Number(val1) - Number(val2)) * (Math.pow(10, roundOff))) / (Math.pow(10, roundOff)) :
                operators == '*' ? Math.round((Number(val1) * Number(val2)) * (Math.pow(10, roundOff))) / (Math.pow(10, roundOff)) + (roundOff > 2 ? 0.000000000001 : 0) :
                operators == '/' ? Math.round((Number(val1) / (val2 != 0 ? Number(val2) : 1)) * (Math.pow(10, 12))) / (Math.pow(10, 12)) : 0
        );
  }
}