<div class="card m-0">
  <div class="card-header d-flex justify-content-between">
    <h5 class="card-title m-0">{{(this.saveAsTypeDetail=="Published" ? 'Common.Publish' : 'Common.Draft') | translate }} </h5>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="card-body">
    <div class="card p-1">
      <h5 class="card-title">
        {{'ReportsModule.Cover page' | translate}}
      </h5>
      <div class="row ">
        <form class="form-horizontal col-md-8" #registerForm="ngForm" novalidate>
          <div class="form-group row">
            <label class="col-md-3 col-form-label col-form-label-sm" for="reportTitle">
              {{'ReportsModule.Report Title' | translate}}<span class="text-danger">*</span></label>
            <div class="col-md-8">
              <input class="form-control form-control-sm" id="reportTitle" required name="reportTitle"
                #reporttitle="ngModel" [(ngModel)]="reportTitle" maxlength="51"  type="text"/>
              <span class="text-danger" *ngIf="(reporttitle.invalid && isSubmitted)">
                {{'ReportsModule.Title is required' | translate}}
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-form-label col-form-label-sm" for="Date">
              {{'Common.Period' | translate}} <span class="text-danger">*</span></label>
            <div class="col-md-8">
              <input required class="form-control form-control-sm" id="Date" name="Date" #dateValid="ngModel" disabled
                [(ngModel)]="date" />
              <span class="text-danger" *ngIf="(dateValid.invalid && isSubmitted)">
                {{'ReportsModule.Date is required' | translate}}
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-form-label col-form-label-sm" for="Organization">
              {{'Organization' | translate}}<span class="text-danger">*</span></label>
            <div class="col-md-8">
              <ng-select required [multiple]="true" class="auto-grow" id="org" [items]="selectedorg"
                 name="orgSelected" [(ngModel)]="orgSelected" bindLabel="title" [disabled]="actionType=='Rename' ? true : false"
                [closeOnSelect]="false" placeholder="{{'ReportsModule.Select Organization' | translate}}">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngIf="items.length > 0">
                    <span class="ng-value-label" *ngIf="orgSelected.length == 1">{{orgSelected[0]['title']}} </span>
                    <span class="ng-value-label" *ngIf="orgSelected.length > 1">{{orgSelected.length}} {{'ReportsModule.Organization selected' | translate}} </span>
                  </div>
                </ng-template>

                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item$-{{index}}" type="checkbox" [ngModel]="item$.selected" 
                    [ngModelOptions]="{standalone: true}" /> {{item.title}}
                </ng-template>
              </ng-select>
              <span class="text-danger" *ngIf="(registerForm.form.controls.orgSelected?.invalid && isSubmitted)">
                {{'ReportsModule.Organization is required' | translate}}
              </span>
            </div>


          </div>
          <!-- <div class="form-group row">
        <label class="col-md-3 col-form-label col-form-label-sm" for="preparedBy">
            Prpared By</label>
        <div class="col-md-8">
          <ng-select [multiple]="false" class="auto-grow" id="org" [items]="userList" name="preparedBy" [(ngModel)]="preparedBy"
           [closeOnSelect]="true" [clearable]="false" >     </ng-select>
        </div>
      </div> -->
        </form>
      </div>

    </div>
  </div>
  <div class="card-footer">
    <div class="float-right">
      <button type="button" class="btn btn-secondary"
        (click)="closeModal()">{{'Common.Close' | translate}}</button>
      <button type="button" class="btn btn-info" (click)="publish(registerForm)">{{(this.saveAsTypeDetail=="Published"  ?(this.actionType=="Rename" ? 'Common.Update Publish' : 'Common.Publish') : (actionType=="Rename" ? 'Common.Update Draft' : 'Common.Draft' )) | translate}}</button>
    </div>
  </div>

</div>
