export class PDFMake {
  public orgLogo: boolean = false;
  public title: any;
  public Currentdate: any;
  public tileAlginment: string = "center";
  public fromDate: string;
  public tODate: string;
  public firstColumnName: string;
  public bankAccName:any;
  public bankAccNo:any;
  public columns: any = []; // header columns
  // public rightAglinColumns: string[] = []; //Money and Qty right alignment list
  public data: any = []; //Input List
  public notes: any = [];
  public downloadName: string;
  public layout: PDFLayout; //default "p" portrait OR  landscape "l"
  public Columnswidth: tableColumn;
  public pageMargins: number[] = [20, 80, 20, 60];   // margin: [left, top, right, bottom]
  public GroupColumn: string;
  public GroupSubTotal: any = [];
  public OrganizationName: string;
  public filterlist: any = [];
  public CustomHeaders: CustomHeader[];
  public CHOrientation: CustomHeaderOrientation;
  public GSTCollected: any = [];
  public GSTPaid: any = [];
  public GSTCollected_ColumnDef: any = [];
  public GSTPaid_ColumnDef: any = [];
  public GSTAccountTransaction_ColumnDef: any = [];
  public GSTAccountTransactions: any = [];
  public GSTOwing_ColumnDef: any = [];
  public GSTOwing: any = [];
  public GSTAccountSummary_ColumnDef: any = [];
  public GSTAccountSummary: any = [];
  public lastTransactioncolumnindex: number;
}

export enum PDFLayout {
  portrait = 1,
  landscape = 2
}

export enum tableColumn {
  auto = 1,
  manual = 2
}
export enum reporttype {
  PDFDownload,
  ExcelDownload,
  SubTotal
}

export interface CustomHeader {
  fieldText: string;
  fieldValue: string;
}

export enum CustomHeaderOrientation {
  vertical,
  horizontal
}